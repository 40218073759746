

import React, { Component } from 'react';
import { Grid, Button, Typography,Toolbar, AppBar, Breadcrumbs, Paper, Container} from '@material-ui/core';
import {AddCircle, Timeline,CheckCircle, Info, Warning, Assignment, Class, NavigateNext} from '@material-ui/icons';
import Header from '../../../Layout/Header';
import Footer from '../../../Layout/Footer';



class Home extends Component {

    render() {

        return (
        <>
        <Header/>
        <AppBar position="static">
                    <Toolbar variant="dense">
                        <Breadcrumbs aria-label="breadcrumb"  separator={<NavigateNext style={{ color: '#d1d1d1'}} fontSize="small" />}>         
                        <Typography variant="caption" style={{ color: '#ffffff'}}>Home</Typography>
                        </Breadcrumbs>
                    </Toolbar>
        </AppBar>

        <Container>
        <Grid container justify="space-between" spacing={2} style={{ display: 'flex', padding: 50 }}>
                <Grid item md={9} xs={12}>
                                    
                    <Typography variant="h5" style={{color: '#525252', padding:20}}>                      
                        <b>Request for Approval to Enroll for Open University</b>
                    </Typography>
                    <Typography variant="subtitle2" style={{padding:20}}>
                    Important Information before you initiate a Request for Approval<br/>
                    </Typography>
                    {/* Section 1 */}
                    <Paper style={{ padding: '20px', marginBottom: 10 }}>
                        <Typography variant="h6" gutterBottom>
                        <Assignment style={{ verticalAlign: 'middle' }} /> Before Starting
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        Before you Create Request, be sure you have completed <b>Step 1</b> of the <a href="https://extension.fullerton.edu/ou/register.aspx" target='_blank' rel="noopener noreferrer">OU General Instructions</a> to be assigned an Open University enrollment appointment (registration date) for this semester. An enrollment appointment is necessary to enroll in classes. You can view <a href="https://ueestatic.fullerton.edu/static/ou/pdf/view-enrollment-appointment.pdf" target='_blank' rel="noopener noreferrer">enrollment appointments</a> in the Manage Classes tile in the Student Homepage. <br/><br/>
                        You can enroll in <b>open</b> sections in the <a href="https://csuf-erp.screenstepslive.com/m/70025/l/1454310-adding-a-classManage" target='_blank' rel="noopener noreferrer">Manage Classes</a> tile without permission if the database recognizes requisites are met. 
                        </Typography>
                    </Paper>

                    {/* Section 2 */}
                    <Paper style={{ padding: '20px', marginBottom: 10  }}>
                        <Typography variant="h6" gutterBottom>
                        <Info style={{ verticalAlign: 'middle' }} /> Acknowledge Identification
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        You will acknowledge identification, eligibility, and course section information through this application to request approval to enroll into specific course sections.
                        </Typography>
                    </Paper>

                    {/* Section 3 */}
                    <Paper style={{ padding: '20px', marginBottom: 10 }}>
                        <Typography variant="h6" gutterBottom>
                        <CheckCircle style={{ verticalAlign: 'middle' }} /> Request Approval
                        </Typography>
                        <Typography variant="body2" gutterBottom paragraph>
                        Your request for approval may include multiple classes. Each course section will be routed to the instructor for an approval decision.<br/>
                        Multiple course sections can be requested, but you may not request multiple sections of the same course in one transaction.<br/>
                        If you are not approved to add a specific course section, then you may return to this site to initiate a request for another section of the same course in a new transaction.<br/>
                        After you submit the request, you will be provided information on how to track the status of your request.
                        </Typography>
                    </Paper>

                    {/* Section 4 */}
                    <Paper style={{ padding: '20px', marginBottom: 10  }}>
                        <Typography variant="h6" gutterBottom>
                        <CheckCircle style={{ verticalAlign: 'middle' }} /> Instructor Approval
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        If approved by the instructor, your request for approval to add each course section is then routed to the department of each course.
                        </Typography>
                    </Paper>

                    {/* Section 5 */}
                    <Paper style={{ padding: '20px', marginBottom: 10 }}>
                        <Typography variant="h6" gutterBottom>
                        <Class style={{ verticalAlign: 'middle' }} /> Registration
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        Registration into the course is completed by you in the Manage Classes tile in your Student Homepage via the CSUF Portal. If the request for approval is approved, then information is communicated to your email address used for the enrollment transaction. Instructions will include to log into the CSUF Portal, select the Student Homepage, then go to the Manage Classes tile to complete registration for approved sections only. Immediately after registering into the course, select the Financial Account tile and pay fees in full at the same time as registration. Failure to pay fees in full upon registration will result in enrollment cancellation possibly without notice. Do not let this occur. Protect your registration!
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        At the time of registration and before required payment, please provide additional information, such as a cross-enrollment certification form, third-party billing, information on a pending loan, or to request a payment plan (if eligible) to Extension Student Services directly at 657.278.2611 during business hours or at eipconnect@fullerton.edu. Always include full name and CWID and complete information.
                        </Typography>
                    </Paper>

                    {/* Section 6 */}
                    <Paper style={{ padding: '20px', marginBottom: 10  }}>
                        <Typography variant="h6" gutterBottom>
                        <Warning style={{ verticalAlign: 'middle' }} /> Registration Holds
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        Please address all Registration Holds in your Tasks title in the Student Homepage. An approved enrollment will not be processed if you have active academic or registration holds that need to be waived or removed. Address these first.
                        </Typography>
                    </Paper>

                    {/* Section 7 */}
                    <Paper style={{ padding: '20px', marginBottom: 10  }}>
                        <Typography variant="h6" gutterBottom>
                        <Warning style={{ verticalAlign: 'middle' }} /> Active Wait-lists
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        The waitlist is for admitted students only. However, an approved request for approval for a waitlisted course section is possible, and to the discretion of the instructor, and academic department of the course, as well as the safety capacity of the classroom.<b> Waitlists expire on Monday of week two of the semester.</b> If the instructor has approved you to add a waitlisted section, <b>the request will NOT route to the academic department for review until Monday of week two of the semester,</b> which is after waitlists expire. <br/>
                        If the instructor initially approves you to add a waitlisted section, please email the professor of record with your name and CWID requesting view access to Canvas, so there is no delay in course access.<br/> <b>Disclaimer:</b> View Access to Canvas is NOT official registration and you will eventually be dropped from Canvas if you do not officially register upon academic department approval. 
                        </Typography>
                    </Paper>

                    {/* Section 8 */}
                    <Paper style={{ padding: '20px', marginBottom: 10  }}>
                        <Typography variant="h6" gutterBottom>
                        <CheckCircle style={{ verticalAlign: 'middle'}} /> Complete Enrollment
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        Your official enrollment into any course section is not complete until you have initiated registration through the Manage Classes tile in the Student Homepage AND have paid fees in full under the Financial Account tile in the Student Homepage. All OU fees are due in full at the time of registration. 
                        </Typography>
                    </Paper>

                </Grid>
                <Grid item md={3} xs={12}>
                <Grid container spacing={2} direction="column" style={{marginTop: 20}}>
                    <Grid item xs={12} md={12}>
                        
                        <Button variant="contained" color="primary" fullWidth  href='../app/create' style={{ height:65}} startIcon={<AddCircle />}> Create Request </Button>
                        {/*<Alert severity="info">You may request approval and enrollment for multiple classes with a single request. Each class in your request will be routed to the instructor and department for an approval decision.</Alert>*/}
                    </Grid>
                    <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" fullWidth href='../app/track' style={{height:65}} startIcon={<Timeline />}> Track Request </Button>
                    </Grid>
                    <Grid item>
                     <Typography variant="overline">
                        Important Notice:</Typography><Typography variant="caption">  Use the latest versions of Google Chrome, Safari or Firefox browsers for optimum use of this interface.  
                     </Typography>
                 </Grid>
                </Grid>
                </Grid>

                 </Grid>
                 </Container>
        <Footer/>
        </>
        );
    }
}

export default (Home);