import React from 'react';
import { Typography, Link, Container, Grid, Box} from '@material-ui/core';
import Facebook from './Facebook.png';
import Instagram from './Instagram.png';
import Linkedin from './Linkedin.png';
import Youtube from './Youtube.png';
import Twitter from './Twitter.png';

export default function Footer() {

  return    <footer style={{ backgroundColor: '#202020', padding: '10px 0', marginTop: 'auto' }}>
  <Container style={{ marginTop: 10 }}>
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Typography variant="caption" style={{ color: '#ffffff' }} paragraph>
          Extension<br />
          {'© ' + new Date().getFullYear() + ' '}
          <Link color="inherit" href="http://www.fullerton.edu/">
            California State University, Fullerton.
          </Link>
          All Rights Reserved.
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box style={{ float: 'right' }}>
          <img src={Facebook} alt="Facebook" style={{ height: 25 }} />
          <img src={Instagram} alt="Instagram" style={{ height: 25, marginLeft: 5 }} />
          <img src={Linkedin} alt="Linkedin" style={{ height: 25, marginLeft: 5 }} />
          <img src={Youtube} alt="Youtube" style={{ height: 25, marginLeft: 5 }} />
          <img src={Twitter} alt="Twitter" style={{ height: 25, marginLeft: 5 }} />
        </Box>
      </Grid>
    </Grid>
  </Container>
</footer>

}
