import React, { PureComponent } from 'react';
import { TextField, Grid, Typography, FormControlLabel, Radio, RadioGroup, FormControl, InputLabel, Select } from '@material-ui/core';
import { WrapperContext } from './WrapperContext';



export default class StudentInformation extends PureComponent{
    static contextType = WrapperContext; 
    componentDidMount() {
        window.scrollTo(0, 0)
      } 
    render(){

    return(
        <>
        
        <Grid container spacing={2} direction="column" style={{ padding: 10 }}>
                <Grid item>
                    <Typography variant="subtitle1">
                    <b>Step 5 of 7: Student Information</b>
                    </Typography>
                </Grid>
        </Grid>
        <Grid container spacing={2} direction="column" style={{padding: 10}}>
            <Grid item>
                <Typography variant="body2"><b>Student Information</b></Typography> 
            </Grid>
            <Grid item container spacing={1}>
                <Grid item md={4} xs={6}>
                    <TextField autoComplete="off" fullWidth variant="outlined" label="First Name" name="firstname" onChange={this.context.handleChange('firstname')} value={this.context.firstname} color="primary" InputLabelProps={{shrink: true}}/>
                    {this.context.errorfirstname.length > 0 && (<Typography variant="caption" style={{color: "#ff0000"}}>{this.context.errorfirstname}</Typography>)}
                </Grid>
                <Grid item md={4} xs={6}>
                     <TextField autoComplete="off" fullWidth variant="outlined" label="Last Name" name="lastname" onChange={this.context.handleChange('lastname')} value={this.context.lastname} color="primary" InputLabelProps={{shrink: true}}/>
                     {this.context.errorlastname.length > 0 && (<Typography variant="caption" style={{color: "#ff0000"}}>{this.context.errorlastname}</Typography>)}
                </Grid>
            </Grid>
            <Grid item container spacing={1}>
                <Grid item md={4} xs={6}>
                    <TextField autoComplete="off" fullWidth variant="outlined" label="Email" name="email" onChange={this.context.handleChange('email')} value={this.context.email} color="primary" InputLabelProps={{shrink: true}}/>
                    {this.context.erroremail.length > 0 && (<Typography variant="caption" style={{color: "#ff0000"}}>{this.context.erroremail}</Typography>)}
                </Grid>
                <Grid item md={4} xs={6}>
                     <TextField autoComplete="off" fullWidth variant="outlined" label="Confirm Email" name="confirmemail" onChange={this.context.handleChange('confirmemail')} value={this.context.confirmemail} color="primary" InputLabelProps={{shrink: true}}/>
                     {this.context.errorconfirmemail.length > 0 && (<Typography variant="caption" style={{color: "#ff0000"}}>{this.context.errorconfirmemail}</Typography>)}
                </Grid>
            </Grid>
            <Grid item container spacing={1}>
                <Grid item md={4} xs={6}>
                    <TextField autoComplete="off" fullWidth variant="outlined" label="Home Phone" name="phonehome" onChange={this.context.handleChange('phonehome')} value={this.context.phonehome} color="primary" InputLabelProps={{shrink: true}}/>
                    {this.context.errorphonehome.length > 0 && (<Typography variant="caption" style={{color: "#ff0000"}}>{this.context.errorphonehome}</Typography>)}
                </Grid>
                <Grid item md={4} xs={6}>
                     <TextField autoComplete="off" fullWidth variant="outlined" label="Mobile Phone" name="phonemobile" onChange={this.context.handleChange('phonemobile')} value={this.context.phonemobile} color="primary" InputLabelProps={{shrink: true}}/>
                </Grid>
            </Grid>
            <Grid item md={8} xs={12}>
                    <TextField autoComplete="off" fullWidth variant="outlined" type="date" label="Birthdate" name="birthdate" onChange={this.context.handleChange('birthdate')} value={this.context.birthdate} color="primary" InputLabelProps={{shrink: true}}/>
                    {this.context.errorbirthdate.length > 0 && (<Typography variant="caption" style={{color: "#ff0000"}}>{this.context.errorbirthdate}</Typography>)}
            </Grid>
            <Grid item md={8} xs={12}>
            <FormControl fullWidth variant="outlined">
                 <InputLabel htmlFor="outlined-Select a Gender">Select a Gender</InputLabel>
                <Select autoComplete="off" native name="gender" label="Select a Gender" value={this.context.gender} onChange={this.context.handleChange('gender')}>
                    <option aria-label="None" value="" />
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Non-binary">Non-binary</option>
                </Select>
            </FormControl>  
            {this.context.errorgender.length > 0 && (<Typography variant="caption" style={{color: "#ff0000"}}>{this.context.errorgender}</Typography>)}    
            </Grid>
            <Grid item>
                <br/>
                <Typography variant="body2"><b>Address Information</b></Typography> 
            </Grid>
            <Grid item md={8} xs={12}>
                <TextField autoComplete="off" fullWidth variant="outlined" label="Address Line 1" name="address1" onChange={this.context.handleChange('address1')} value={this.context.address1} color="primary" InputLabelProps={{shrink: true}}/>
                {this.context.erroraddress1.length > 0 && (<Typography variant="caption" style={{color: "#ff0000"}}>{this.context.erroraddress1}</Typography>)}
            </Grid>
            <Grid item md={8} xs={12}>
                <TextField autoComplete="off" fullWidth variant="outlined" label="Address Line 2" name="address2" onChange={this.context.handleChange('address2')} value={this.context.address2} color="primary" InputLabelProps={{shrink: true}}/>
            </Grid>
            <Grid item container spacing={1}>
                <Grid item md={4} xs={6}>
                    <TextField autoComplete="off" fullWidth variant="outlined" label="City" name="city" onChange={this.context.handleChange('city')} value={this.context.city} color="primary" InputLabelProps={{shrink: true}}/>
                    {this.context.errorcity.length > 0 && (<Typography variant="caption" style={{color: "#ff0000"}}>{this.context.errorcity}</Typography>)}
                </Grid>
                <Grid item md={4} xs={6}>
                     <TextField autoComplete="off" fullWidth variant="outlined" label="State/Province" name="state" onChange={this.context.handleChange('state')} value={this.context.state} color="primary" InputLabelProps={{shrink: true}}/>
                     {this.context.errorstate.length > 0 && (<Typography variant="caption" style={{color: "#ff0000"}}>{this.context.errorstate}</Typography>)}
                </Grid>
            </Grid>
            <Grid item container spacing={1}>
                <Grid item md={4} xs={6}>
                     <TextField autoComplete="off" fullWidth variant="outlined" label="Zip/PostalCode" name="zip" onChange={this.context.handleChange('zip')} value={this.context.zip} color="primary" InputLabelProps={{shrink: true}}/>
                     {this.context.errorzip.length > 0 && (<Typography variant="caption" style={{color: "#ff0000"}}>{this.context.errorzip}</Typography>)}
                </Grid>
                <Grid item md={4} xs={6}>
                     <TextField autoComplete="off" fullWidth variant="outlined" label="Country" name="country" onChange={this.context.handleChange('country')} value={this.context.country} color="primary" InputLabelProps={{shrink: true}}/>
                     {this.context.errorcountry.length > 0 && (<Typography variant="caption" style={{color: "#ff0000"}}>{this.context.errorcountry}</Typography>)}
                </Grid>
            </Grid>
            <Grid item>
                <br/>
                <Typography variant="body1"><b>Why are you registering through Open University?</b></Typography> 
            </Grid>
            <Grid item md={8} xs={12}>
            <FormControl fullWidth variant="outlined">
                 <InputLabel htmlFor="outlined-Select an answer-native-simple">Select an answer</InputLabel>
                <Select autoComplete="off" autoWidth={true} native name="immigrationStatus" label="Select an answer" value={this.context.reason} onChange={this.context.handleChange('reason')}>
                    <option aria-label="None" value="" />
                    <option value="1">Undergrad Completion and Re-Entry to CSUF</option>
                    {/*<option value="2">DQ from CSUF</option>*/}
                    <option value="3">Prerequisite for Grad Program</option>
                    <option value="4">Graduate Level Course</option>
                    <option value="5">Guest (Other College)</option>
                    <option value="6">Prerequisite for Credential</option>
                    <option value="7">Credential Course</option>
                    <option value="8">Future Undergrad Transfer</option>
                    <option value="10">University Semester Abroad Program at CSUF (USA)</option>
                    <option value="11">Requisites have not been met</option>
                    <option value="12">Department consent required</option>
                    <option value="13">Class is closed</option>
                    <option value="9">Other</option>
                </Select>
            </FormControl>
            {this.context.errorreason.length > 0 && (<Typography variant="caption" style={{color: "#ff0000"}}>{this.context.errorreason}</Typography>)}
            </Grid>
            <div style={{ display: this.context.reason === "5" || this.context.reason === "8" ? "block" : "none"}}>
                <Grid item md={4} xs={6}>
                  <TextField autoComplete="off" fullWidth variant="outlined" label="Transfering From: College Name" margin="dense" name="reasonCollege" onChange={this.context.handleChange('reasonCollege')} value={this.context.reasonCollege} color="primary" InputLabelProps={{ shrink: true }} />
                </Grid>
            </div>
            <div style={{ display: this.context.reason === "1" ? "block" : "none"}}>
                <Grid item md={4} xs={6}>
                <RadioGroup row aria-label="reasonreentry" name="reasonreentry" onChange={this.context.handleChange('reasonreentry')} defaultValue={this.context.reasonreentry}>
                    <FormControlLabel value="1" control={<Radio color="primary" size="small" />} label="Previous undergrad disqualified student from CSUF" />
                    <FormControlLabel value="2" control={<Radio color="primary" size="small" />} label="Previous undergrad (non-disqualified) student from CSUF"/>
                </RadioGroup> 
                {this.context.errorreasonreentry.length > 0 && (<Typography variant="caption" style={{color: "#ff0000"}}>{this.context.errorreasonreentry}</Typography>)}
                </Grid>
            </div>
            <div style={{ display: this.context.reason === "9" ? "block" : "none"}}>
            <Grid item md={4} xs={6}>
                  <TextField autoComplete="off" fullWidth variant="outlined" multiline rows={2} label="Other" margin="dense" name="reasonOther" onChange={this.context.handleChange('reasonOther')} value={this.context.reasonOther} color="primary" InputLabelProps={{ shrink: true }} />
                  </Grid>
            </div>
            <Grid item><br/><br/></Grid>
            
        </Grid>
        </>
        )
    }
}