import React, { Component } from 'react';
import axios from 'axios';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: {}
    };

    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.uploadDocuments = this.uploadDocuments.bind(this);
  }

  handleFileUpload(e, classnbr) {
    const file = e.target.files[0];
    this.setState(prevState => ({
      files: { ...prevState.files, [classnbr]: file }
    }), this.uploadDocuments);
  }

  uploadDocuments() {
    alert('test');
    const { files } = this.state;
    for (let classnbr in files) {
      const formData = new FormData();
      formData.append('file', files[classnbr]);
      formData.append('classNumber', classnbr);

      axios.post('https://your-api-endpoint/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        console.log(`File uploaded successfully for class number ${classnbr}`, response.data);
      })
      .catch(error => {
        console.error(`There was an error uploading the file for class number ${classnbr}`, error);
      });
    }
  }

  render() {
    return (
      <div>
        {/* Example input; replace x.classnbr with your actual class number */}
        <input type="file" onChange={(e) => this.handleFileUpload(e, '12345')} />         
      </div>
         
    );
  }
}

export default FileUpload;