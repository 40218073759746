import React, { PureComponent } from 'react';
import {AppBar,Toolbar, Grid, Typography, Paper, Container, TextField, Divider, Button,CircularProgress, Modal, Backdrop,Fade, Checkbox, Breadcrumbs, Link} from '@material-ui/core';
import { ArrowRightSharp, Check, Clear, Home, NavigateNext } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import Emblem from '../../../Layout/CSUF-emblem.png';
import Header from '../../../Layout/Header';
import Footer from '../../../Layout/Footer';
import WaitlistIcon from '../../../Layout/Waitlist.gif';
import ClosedIcon from '../../../Layout/Closed.gif';
import OpenIcon from '../../../Layout/Open.gif';

const validlettersonlyRegex = RegExp(
    /^[a-zA-Z][a-zA-Z\s]*$/
  );

  const styles = theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  });


export class Approval extends PureComponent{
    constructor(props){
        super(props);
        this.state={
            request:[],
            loading: false,
            signature:'',
            signatureerror: '',
            comment:'',
            commentforstudent: '',
            commenterror:'',
            loadingapprove: false,
            loadingdeny: false,
            submittedsuccessfully: false,
            approved: 0,
            openModal : false,
            action: '',
            permitplaced: false,
            permiterror: '',
            approvalid: 0,
            accountid: 0,
            shibbfirstname: '',
            shibblastname: '',
            shibbemail: ''
        };
    }

    onbuttonModalClick =id =>() =>{
            
        if(id === 0 && this.state.commentforstudent === '' && this.state.request.commentforstudent === '' )
        {
            this.setState({commenterror:'Comment is required.'});
            
        }
        else if(this.state.signature === '')
        {
            this.setState({signatureerror:'Signature is required.',commenterror:''});
        }
        else if(this.state.request.typeid === 2 && this.state.permitplaced === false && id === 1)
        {
            this.setState({permiterror:'Please place a permit. '});
        }
        else
        {
            if (id === 1)
            {
                this.setState({action: 'approve',openModal : true});
            }else
            {
                this.setState({action: 'deny',openModal : true });
            }
        }
        
    }

    onCloseModal = ()=>{
        this.setState({openModal : false});
    }

    updateApproval(id){
        alert(id);
    }

    onbuttonClick =id =>() =>{

        if(id === 0)
        {
            this.setState({loadingdeny: true, signatureerror:'', commenterror:''});
            
        }else if(id === 1)
        {
            this.setState({loadingapprove: true, signatureerror:'', commenterror:''});
        }

        let requestBody = {
            approvalid: this.state.request.id,
            signature: this.state.signature,
            approved: id,
            typeid: this.state.request.typeid,
            lineid: this.state.request.lineid,
            comment: this.state.commentforstudent,
            apptypeid: this.state.request.apptypeid,
            waitlistmax: this.state.request.waitlistmax,
            waitlisttotal: this.state.request.waitlisttotal,
            enrlstatus: this.state.request.enrlstatus
        }

        this.setState({approved: id});

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
            body: JSON.stringify(requestBody)
        };

        //alert(JSON.stringify(requestBody))
        //console.log(JSON.stringify(requestBody))
        //https://cors-anywhere.herokuapp.com/
        fetch('https://extension.fullerton.edu/data/ou/updateapproval', requestOptions)
        .then(response => response.json())
        .then(data =>{
            if (data)
                {
                    this.setState({submittedsuccessfully: true, loadingapprove: false, loadingdeny: false,openModal : false});
                }
            })
        .catch(data => {alert(data);});
        

    };

    handleChange = input => e => {
        this.setState({[input]: e.target.value}); 
        switch (input) {
            case 'signature': 
              this.setState({signatureerror: validlettersonlyRegex.test(e.target.value)? '' : 'Please enter a valid Signature.'});
              break;
            case 'permit':
              this.setState({permitplaced: e.target.checked, permiterror: e.target.checked? '': 'Please place a permit.'});
              break;
            default: 
              break;
        }
        //this.setState({signatureerror: validlettersonlyRegex.test(e.target.value)? '' : 'Please enter a valid Signature.'});
    }
 
    componentDidMount() {
        this.setState({loading: true});
        this.callBackendAPI()
        .then(res => {
        // Get the current URL and parse the query string
        const { location } = this.props;
        const searchParams = new URLSearchParams(location.search);

        // Get the values of the query parameters
        const id = searchParams.get('id');
        const accountid = searchParams.get('accountid');
        const returnUrl = 'approval';


        this.setState({loading: true, approvalid: id, accountid: accountid});

        if (res.express === 'Shibboleth Session not established') {
            const redirectURL = `https://extension.fullerton.edu/app/express/auth?approvalid=${id}&accountid=${accountid}&return=${returnUrl}`;
            window.location.href = redirectURL;
            return;
          } 

        this.setState(
            {
              shibbfirstname: res.firstname,
              shibblastname: res.lastname,
              shibbemail: res.email
              //accountid: id
            },
        () => {

        let requestBody = {
            approvalid: id
        };
        

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
            body: JSON.stringify(requestBody)
        };
        /*https://cors-anywhere.herokuapp.com/*/
        fetch('https://extension.fullerton.edu/data/ou/approval', requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            if (data) {
                this.setState({ request: data, loading: false });
                if (data.status < 2) {
                    this.setState({ submittedsuccessfully: true, approved: data.status });
                }
            }
        })
        .catch(error => {
            alert('Error:', error);
        });  

          });
        }) 


      };

      callBackendAPI = async () => {
        const response = await fetch('/app/express/attributes');
        const body = await response.json();
    
        if (response.status !== 200) {
          throw Error(body.message) 
        }
        return body;
      };




    render(){

        const { classes } = this.props;
        const classStatus =
        this.state.request.enrlstatus === "C" && this.state.request.waitlisttotal === this.state.request.waitlistmax
          ? ' Closed'
          : this.state.request.enrlstatus === "C" && this.state.request.waitlisttotal < this.state.request.waitlistmax
          ? ' Waitlist'
          : this.state.request.enrlstatus === "O"
          ? ' Open'
          : null;

        const classStatusIcon =
          this.state.request.enrlstatus === "C" && this.state.request.waitlisttotal === this.state.request.waitlistmax
            ? <img src={ClosedIcon} alt = 'close'/>
            : this.state.request.enrlstatus === "C" && this.state.request.waitlisttotal < this.state.request.waitlistmax
            ? <img src={WaitlistIcon} alt='waitlist'/>
            : this.state.request.enrlstatus === "O"
            ? <img src={OpenIcon} alt= 'open'/>
            : null;

            const getFileNameAfterLastDash = (text) => {
                const lastDashIndex = text.lastIndexOf('-');
                return lastDashIndex !== -1 ? text.substring(lastDashIndex + 1) : text;
              };
        
        const deeplink = `https://cmsweb.fullerton.edu/psc/CFULPRD/EMPLOYEE/SA/c/ESTABLISH_COURSES.CLASS_PERMISSION.GBL?Page=CLASS_PERMISSION&Action=U&INSTITUTION=FLCMP&STRM=${this.state.request.term}&SUBJECT=${this.state.request.subject}&CATALOG_NBR=${this.state.request.catalognbr}&CRSE_ID=${this.state.request.crseoffernbr}`;
        

        return(
        
        <>
        <Header/>


    <div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={this.state.openModal}
            onClose={this.onCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
        <Fade in={this.state.openModal}>
          <div className={classes.paper}>
            <Typography id="modal-title" variant="subtitle2" component="h2">
                <strong>Confirm {this.state.action === 'deny'?('Denial'):('Approval')}</strong> 
            </Typography><br/>
            <Typography id="modal-description" variant="body2" sx={{ mt: 2 }}>
                 Are you sure you want to {this.state.action === 'deny'?('deny'):('approve')} this request?
            </Typography><br/>
            <Grid item container spacing={1}>
                <Grid item>
                   <Button startIcon={<Clear/>} variant="contained" style={{height: 40}} onClick={this.onCloseModal} >Cancel</Button>
                </Grid>
                <Grid item>
                {this.state.action === 'deny'?(
                   <Button startIcon={<Clear/>} fullWidth variant="contained" style={{height: 40, backgroundColor:"#8e252a", color: 'white'}} onClick={this.onbuttonClick(0)}>Deny {this.state.loadingdeny && <CircularProgress size={20} style={{color: "#ffffff"}}/>}</Button>
                ):(
                   <Button startIcon={<Check/>} fullWidth variant="contained" style={{ height: 40, float:"right", backgroundColor: "#63b245", color: 'white' }} onClick={this.onbuttonClick(1)}>Approve {this.state.loadingapprove && <CircularProgress size={20} style={{color: "#ffffff"}}/>}</Button>
                )}
                </Grid>
            </Grid> 
          </div>
        </Fade>
      </Modal>
    </div>

        <AppBar position="static">
                    <Toolbar variant="dense">
                    <Breadcrumbs aria-label="breadcrumb"  separator={<NavigateNext style={{ color: '#d1d1d1'}} fontSize="small" />}>         
                        <Link underline="hover" color="inherit" href={`../app/ApprovalDashboard?accountid=${this.state.accountid}`}>
                            <Typography variant="caption" style={{ color: '#d1d1d1'}}>Dashboard</Typography>  
                        </Link>
                        <Typography variant="caption" style={{ color: '#ffffff'}}>Course Approval Request</Typography>
                    </Breadcrumbs>
                    </Toolbar>
        </AppBar>

        <Container  component="main" style={{maxWidth: 960}}>
        
        <Paper style={{padding: 40,marginTop: 20}} elevation={1}>
        <Grid container direction="column" >

        {this.state.loading === true?(<Grid item container justify="center" style={{marginTop: 5}}>
                <Typography variant="h6">Loading... </Typography>{this.state.loading && <CircularProgress size={25}/>} 
            </Grid>): ( <>
        <Grid container direction="column">
        <Grid item container spacing={1} alignItems="center">
            <Grid item>
            <img src={Emblem} alt="Logo" style={{width: 30, height: 57, display: 'block',maxWidth: '100%', maxHeight: '100%'}}/>
            </Grid>
            <Grid item>
                <Typography variant="h5">Open University</Typography>
                <Typography variant="overline">Extension</Typography>  
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography variant="overline">Course Approval Request</Typography>
        </Grid>
        </Grid>
        </Grid>
        <Grid item style={{marginTop: 15}}>
            <Divider/>
        </Grid>
        <Grid item container style={{marginTop: 30, display:'flex'}} >
        <Grid container spacing={1} direction="column" style={{padding: 10}}>
            <Grid item>
                <Typography variant="caption"><b>Request Information</b></Typography> 
            </Grid>
            <Grid item container spacing={2}>
                <Grid item xs={6}>
                    <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="Tracking Number" margin="dense" name="Tracking Number" value={this.state.request.referenceid} InputLabelProps={{shrink: true}}/>
                </Grid>
                <Grid item xs={6}>
                     <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="Submitted Date" margin="dense" name="Submitted Date" value={this.state.request.submitteddate} color="primary" InputLabelProps={{shrink: true}}/>
                </Grid>
            </Grid>
        </Grid>
        <Grid container spacing={1} direction="column" style={{padding: 10}}>
            <Grid item>
                <Typography variant="caption"><b>Class Requested</b></Typography> 
            </Grid>
            <Grid item container spacing={2}>
                <Grid item xs={4}>
                    <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="Term" margin="dense" name="Term" value={this.state.request.term} color="primary" InputLabelProps={{shrink: true}}/>
                </Grid>
                <Grid item xs={4}>
                     <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="Class Number" margin="dense" name="Class Numb" value={this.state.request.classnbr} color="primary" InputLabelProps={{shrink: true}}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="Course" margin="dense" name="Course" value={`${this.state.request.subject}-${this.state.request.catalognbr}-${this.state.request.section}`} color="primary" InputLabelProps={{shrink: true}}/>
                </Grid>
                <Grid item xs={4}>
                     <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="Days and Times" margin="dense" name="Days and Times" value={this.state.request.meeting} color="primary" InputLabelProps={{shrink: true}}/>
                </Grid>
                <Grid item xs={4}>
                     <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="Meeting Dates" margin="dense" name="Meeting Dates" value={`${this.state.request.startdate}-${this.state.request.enddate}`} color="primary" InputLabelProps={{shrink: true}}/>
                </Grid>
                <Grid item xs={4}>
                     <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="Status" margin="dense" name="Status" value={classStatus} InputProps={{startAdornment: classStatusIcon}}/>
                </Grid>
            </Grid>
        </Grid>
        <Grid container spacing={1} direction="column" style={{padding: 10}}>
            <Grid item>
                <Typography variant="caption"><b>File Uploaded</b></Typography> 
            </Grid>
            <Grid item container spacing={2}>
                <Grid item xs={4}>
                    <Typography variant="body2">File: {this.state.request.fileuploaded === 'No file uploaded' ? this.state.request.fileuploaded : (this.state.request.fileuploaded)}</Typography>
                </Grid>
                {this.state.request.fileuploaded !== 'No file uploaded'?
                <Grid item xs={8}>
                <Button size="small" variant="contained" color="primary" href={this.state.request.filesharedlink} target='_blank'>View</Button>
                </Grid>
                :''}
            </Grid>
        </Grid>
        <Grid container spacing={1} direction="column" style={{padding: 10}}>
            <Grid item>
                <Typography variant="caption"><b>Student Information</b></Typography> 
            </Grid>
            <Grid item container spacing={2}>
                <Grid item xs={6}>
                    <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="First Name" margin="dense" name="firstname" value={this.state.request.firstname} color="primary" InputLabelProps={{shrink: true}}/>
                </Grid>
                <Grid item xs={6}>
                     <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="Last Name" margin="dense" name="lastname" value={this.state.request.lastname} color="primary" InputLabelProps={{shrink: true}}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="CWID" margin="dense" name="cwid" value={this.state.request.cwid} color="primary" InputLabelProps={{shrink: true}}/>
                </Grid>
                <Grid item xs={6}>
                     <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="Email" margin="dense" name="email" value={this.state.request.email} color="primary" InputLabelProps={{shrink: true}}/>
                </Grid>
                <Grid item xs={12}>
                <TextField margin="dense" defaultValue={this.state.request.comment} label="Comments from Student" multiline rows={2} style={{backgroundColor: '#ffffff'}} InputLabelProps={{shrink: true}} variant="outlined" size="small" color="primary" fullWidth />
                </Grid>
                <Grid item xs={12}>
                <TextField autoComplete="off" size="small" fullWidth variant="outlined" label="Why are you registering through Open University?" margin="dense" name="Why are you registering through Open University?" value={this.state.request.reason} color="primary" InputLabelProps={{shrink: true}}/>
                <Grid item>
                {this.state.request.college !== ""?(<>
                    <br/><TextField autoComplete="off" fullWidth size="small" variant="outlined" label="College" margin="dense" name="college" value={this.state.request.college} color="primary" InputLabelProps={{shrink: true}}/>
                </>):""} 
                {this.state.request.other !== ""?(<>
                    <br/><TextField autoComplete="off" fullWidth size="small" variant="outlined" label="Other" margin="dense" name="other" value={this.state.request.other} color="primary" InputLabelProps={{shrink: true}}/>
                </>):""} 
                </Grid>
                </Grid>
                <Grid item>
                <br/>
                <Typography variant="caption"><strong>Terms of Service</strong></Typography> 
                </Grid>
                <Grid item>
                    <div>      
                        <Typography display="block" color="textSecondary" variant="caption"><ArrowRightSharp fontSize="inherit"/>Only 24 maximum units earned through Open University at CSUF, can apply towards the cumulative units needed for a bachelor’s degree earned as CSUF. Units earned through open university do not meet residency requirements for a degree at CSUF.</Typography>
                        <Typography color="textSecondary" variant="caption"><ArrowRightSharp fontSize="inherit"/>I have consulted with a graduate advisor at CSUF, if requesting to add a 500-level course. Usually only 9 units of graduate level courses completed through Open university count towards a degree at CSUF.</Typography><br/>
                        <Typography color="textSecondary" variant="caption"><ArrowRightSharp fontSize="inherit"/>I meet the stated prerequisite(s) or equivalent(s) for the course(s) listed below.</Typography><br/>
                        <Typography color="textSecondary" variant="caption"><ArrowRightSharp fontSize="inherit"/>I have read and acknowledged the drop/withdrawal deadlines and refund policies for Open University.</Typography><br/>
                        <Typography variant="caption"><strong>Student Signature:</strong> {this.state.request.firstname} {this.state.request.lastname}</Typography><br/>
                    </div>   
                </Grid>
            </Grid>
        </Grid>
        <Grid container spacing={1} direction="column" style={{padding: 10}}>

        <Grid item>              
                <TextField margin="dense" label="Your Comments for Student" onChange={this.handleChange('commentforstudent')} disabled= {this.state.submittedsuccessfully === true ? true : false} defaultValue={this.state.request.commentforstudent} multiline rows={3} InputLabelProps={{shrink: true}} variant="outlined" size="small" color="primary" fullWidth />
                {this.state.commenterror.length > 0 && <><Typography variant="caption" style={{color: "#ff0000"}}>{this.state.commenterror}</Typography><br/></>}
        </Grid>
        {this.state.request.typeid === 2 && this.state.request.status === 2 ? <>
        <Grid item>
            <br/>
            <Typography variant="caption"><strong>Permit</strong></Typography> (<Typography variant="caption">Click <a href='https://csuf-erp.screenstepslive.com/m/70028/l/1559224' target='_blank' rel="noopener noreferrer">here</a> to review how to place a permit in CMS.</Typography>)
            <br/>
            <Typography variant="caption"><a href={deeplink} target='_blank' rel="noopener noreferrer">Quick Link</a></Typography>
            <br/>
        </Grid>
        <Grid item>
            <Checkbox checked={this.state.permitplaced} size="small" onChange={this.handleChange('permit')} name="chkPermit"  color="primary"/> <Typography variant="caption">"I acknowledge I have placed a permit in CMS for this student into this course section." Approval is not complete without this step.</Typography><br/>
            {this.state.permiterror.length > 0 && <><Typography variant="caption" style={{color: "#ff0000"}}>{this.state.permiterror}</Typography><br/></>}
            
        </Grid>
        </>:<Grid item></Grid>}
        {this.state.request.status === 2 ?<>
        <Grid item style={{marginTop: 20}}>
             <TextField size="small" variant="outlined" name="signature" fullWidth label="Your Signature (Print Full Name)" onChange={this.handleChange('signature')} defaultValue={this.state.signature} InputLabelProps={{shrink: true}}/>
             {this.state.signatureerror.length > 0 && <><Typography variant="caption" style={{color: "#ff0000"}}>{this.state.signatureerror}</Typography><br/></>}
             <Typography variant="caption"><strong>Date:</strong></Typography> <Typography variant="overline">{this.state.request.sysdate}</Typography>
        </Grid>
        </>:<Grid item></Grid>}
        <Grid item spacing={2} container style={{ display:'flex', marginTop: 40}} >
        {this.state.submittedsuccessfully === true?(<>
            <Grid item xs={6}>
                    <Button startIcon={<Home/>} color="inherit" href={`../app/ApprovalDashboard?accountid=${this.state.accountid}`}  variant="contained" style={{height: 50, width: 200}}>View Dashboard</Button>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button startIcon={this.state.approved === 0?<Clear/>:<Check/>} fullWidth variant="contained" style={{height: 50, backgroundColor:this.state.approved === 0?"#8e252a":"#63b245", color: 'white'}}>{this.state.approved === 0?"Denied Succcessfully":"Approved Successfully"} </Button>
            </Grid>
            </>):(<>
            <Grid item xs={6} >
                     <Button startIcon={<Home/>} color="inherit" href={`../app/ApprovalDashboard?accountid=${this.state.accountid}`} target='_blank' variant="contained" style={{height: 50, width: 200}}>View Dashboard</Button>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button startIcon={<Clear/>} fullWidth size="large" variant="contained" style={{height: 50, backgroundColor:"#8e252a", color: 'white'}} onClick={this.onbuttonModalClick(0)}>Deny</Button>
                    <Button startIcon={<Check/>} fullWidth size="large" variant="contained" style={{ marginLeft:"5px", height: 50, float:"right", backgroundColor: "#63b245", color: 'white' }} onClick={this.onbuttonModalClick(1)}>Approve</Button>
            </Grid>
        </>)}
        </Grid>

        </Grid>
        </Grid>
        
        <Grid item container style={{ marginTop: 30}} >
        </Grid>
        </>
        )}
        </Grid>
        </Paper>

        <div style={{ marginTop: 30}}>

        </div>
        </Container>
        <Footer/>
        </>
        )
    }
}
export default withStyles(styles)(Approval);
