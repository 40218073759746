import React, { PureComponent } from 'react';
import { Grid, Typography, Button, InputBase, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress} from '@material-ui/core';
import { withStyles} from '@material-ui/core/styles';
import { WrapperContext } from './WrapperContext';
import { ArrowRightSharp, ArrowLeftSharp, CheckSharp} from '@material-ui/icons';
import { Alert} from '@material-ui/lab';
import WaitlistIcon from '../../../Layout/Waitlist.gif';
import ClosedIcon from '../../../Layout/Closed.gif';
import OpenIcon from '../../../Layout/Open.gif';

const styles = (theme) => ({
    oddRow: {
      backgroundColor: theme.palette.action.hover,
    },
    evenRow: {
      backgroundColor: '#ffffff',
    },
    hoverEffect: {
      '&:hover': {
        backgroundColor: theme.palette.action.selected,
      },
    },
    uploadRow: {
      backgroundColor: '#f7f7f7',
    },
  });



class SearchAndAddClasses extends PureComponent{

    
    constructor(props) {
        super(props)
        this.state={
            open: false,
            openRow: null,
        }

      }


      handleClose = () => {
        this.setState({ open: false });
      }

      handleConfirm = () => {
        // Call the updateCart function passed via props
        this.context.updateCart(this.context.class);
        this.handleClose();
      };

      handleClick = (term) => {
        this.context.setSearchTerm(term);
      }


      handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          this.context.search();
          e.preventDefault();
        }
      };


    static contextType = WrapperContext; 
 
    
    render(){
        
        return(
            <>

    <Grid container spacing={2} direction="column" style={{ padding: 10 }}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                                    <b>Step 3 of 7: Class Search And Add To Request</b>
                        </Typography>
                    </Grid>
    </Grid>

    {this.context.searchterm === ''?(
     <Grid container spacing={2} direction="column" style={{ padding: 10 }}>
                <Grid item xs={12}>
                <Typography variant="body2"><b>Select a Term:</b></Typography>
                </Grid>
                <Grid item xs={12}>
                    <div 
                        onClick={() => this.handleClick(this.context.activeTermValue)}
                        style={{
                            border: '1px solid #ced4da',
                            padding: '20px',
                            cursor: 'pointer'
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#DEE6F2'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
                    >
                        {this.context.activeTermName}
                    </div>
                </Grid>
    </Grid>):('')}

    {this.context.showsearch === true ?(
    <Grid container spacing={2} direction="column" style={{ padding: 10 }}>
        <Grid item xs={12}>
            <Typography variant="body2"><b>Search For Classes:</b></Typography>
        </Grid>
        <Grid item md={12} xs={12}>
                    <Grid item>
                      <Paper component="form" style={{padding: '2px 4px',
                        display: 'flex',
                        alignItems: 'center',
                        }}>
                        <InputBase
                            style={{flex: 1}}
                            name="searchvalue"
                            autoComplete="off"
                            onKeyDown={this.handleKeyDown}
                            placeholder="Enter keyword e.g. course title, subject, catalog number, class number"
                            inputProps={{ 'aria-label': 'Enter keyword e.g. course title, subject, catalog number, class number' }}
                            onChange={this.context.handleChange('searchvalue')} 
                            value={this.context.searchvalue}
                        />
                        <Button style={{padding: 15}} aria-label="search"
                        onClick={()=>{this.context.search()}}
                        disabled={this.context.cart.length === 5}
                        variant="contained"
                        color="primary"
                    
                        >
                            Search {this.context.loading && <CircularProgress color="#ffffff" size={20}/>}
                        </Button>
                        </Paper>
                    </Grid>
        </Grid>
        <Grid item md={4} xs={12}>
            <Typography variant="body2"> <b>Search Tips:</b> Enter specific keyword(s) for immediate retrieval of courses, for example course title, topic, subject and catalog number, class number.</Typography>
        </Grid>
    </Grid>
    ):('')}

{this.context.showsearchResult === true?(
    <Grid container spacing={2} direction="column" style={{ padding: 10 }}>
        <Grid container justify="space-between" spacing={2} style={{ display: 'flex', padding: 10 }}>
            <Grid item>
            <Typography paragraph>
            <Typography variant="body2"><b>Search Results:</b>
            <br/>
            <Typography variant="caption">{this.context.groupedsearchResult.length === 0 ? 'No results were returned. Refine your search by entering a different keyword.': this.context.groupedsearchResult.length + ' Courses with keyword(s):' + this.context.searchvalue }</Typography>
            </Typography>
            </Typography>
            </Grid>
            <Grid item>
            <Button size="medium" onClick={()=>this.context.handlebackClassSearch()} variant="outlined" startIcon={<ArrowLeftSharp/>}>Class Search</Button>    
            </Grid>
        </Grid>
        <Grid item xs={12}>
        <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table">
            <TableBody>
            {this.context.groupedsearchResult.map((result, index) => (
                <TableRow key={index}  sx={{ border: '4px solid #ced4da' }}                          
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#DEE6F2'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
                onClick={() => this.context.showRowDetails(result.rows,result.subjectCatalogNbr,result.descr)}
                >
                <TableCell  style={{ borderRight: 'none'}}>
                   <Typography paragraph> 
                        <Typography variant="subtitle1"><b>{result.subjectCatalogNbr}</b></Typography>
                        <Typography variant="caption">{result.descr}</Typography> <br/>
                        <Typography variant="caption">{result.rowCount} Class Options Available</Typography>
                   </Typography>
                </TableCell>
                <TableCell align="right"  style={{ borderLeft: 'none'}}>{<ArrowRightSharp/>}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
        </Grid>
    </Grid>
):('')}

    {this.context.showsearchDetails === true ?(
          <Grid>
            <Grid container justify="space-between" spacing={2} style={{ display: 'flex', padding: 10 }}>
                <Grid item>
                <Typography variant="body2"><b>Class Details:</b></Typography>
                </Grid>
                <Grid item>
                <Button size="medium" onClick={()=>this.context.handlebackClassSearchResults()} variant="outlined" startIcon={<ArrowLeftSharp/>}>Class Search Results</Button>    
                </Grid>
            </Grid>
            <Grid item xs={12}>
                    <Typography paragraph style={{marginLeft: 10}}>
                        <Typography variant="subtitle1"><b>{this.context.searchSelectedCourse}</b><br/></Typography>
                        <Typography variant="body1">{this.context.searchSelectedCourseDescr}</Typography>
                    </Typography>
            </Grid>
            <Grid item>
            
            <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                    <TableCell colSpan={6}>
                    <Typography variant="body2"><b>Select a class option to add to the enrollment approval request: </b></Typography>
                    </TableCell>
                    <TableCell>
                    <Typography variant="body2"><b>{this.context.selectedRowDetails.length} options</b></Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Class Number</TableCell>
                  <TableCell>Course</TableCell>
                  <TableCell>Days and Times</TableCell>
                  <TableCell>Meeting Dates</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Units</TableCell>
                  <TableCell>Instructor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.context.selectedRowDetails.map((row, rowIndex) => (
                  <TableRow key={rowIndex} sx={{ border: '4px solid #ced4da' }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#DEE6F2'}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
                    onClick={()=>{this.context.addClass(row)}}
                    >
                    <TableCell>{row.classnbr}</TableCell>
                    <TableCell>{row.subject}-{row.catalognbr}-{row.section}</TableCell>
                    <TableCell>{row.meeting}</TableCell>
                    <TableCell>{row.startdate}-{row.enddate}</TableCell>
                    <TableCell>{(row.enrlstatus === "C" && (row.waitlistmax === row.waitlisttotal)) ? <><img src={ClosedIcon} alt="close"/><span><Typography variant="caption"> Closed</Typography></span></>: ((row.enrlstatus === "C" && (row.waitlisttotal < row.waitlistmax)) ? <><img src={WaitlistIcon} alt="waitlist"/><span><Typography variant="caption"> Wait List</Typography></span></> : (row.enrlstatus === "O" ? <><img src={OpenIcon} alt="open"/><span><Typography variant="caption"> Open</Typography></span></>: ''))} </TableCell>
                    <TableCell>{row.unitsmax}</TableCell>
                    <TableCell>{row.instructorname}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
            </Grid>
          </Grid>
    ):('')}
         
         {this.context.showclassAdded === true ?(
            <Grid container spacing={2} direction="column" style={{ padding: 10 }}>
                <Grid item xs={12}>
                    <Alert  severity="success">This class has been added to your Enrollment Approval Request</Alert>
                </Grid>
                <Grid item xs={12}>
                    <div style={{border: '1px solid #ced4da', padding: 10 }}>
                            <Grid container direction="row">
                                <Grid item>
                                    <CheckSharp/>
                                </Grid>
                                <Grid item>
                                <Typography paragraph style={{marginLeft: 10}}>
                                <Typography variant="subtitle1"><b>{this.context.searchSelectedCourse}</b><br/></Typography>
                                <Typography variant="body1">{this.context.searchSelectedCourseDescr}</Typography>
                                </Typography>
                                </Grid>
                            </Grid>
                    </div>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Grid container justify="space-between" spacing={2} style={{ display: 'flex', padding: 10 }}>
                    <Grid item>
                    <Typography variant="body2" paragraph>
                                <strong>{this.context.cart.length} class(es)</strong> has been added to your enrollment approval request.
                                <br />
                                You can add <strong>{5 - this.context.cart.length} more class(es)</strong> to this approval request.
                    </Typography>
                    </Grid>
                    <Grid item>
                    <Button size="medium" onClick={()=>this.context.handlebackClassSearch()} variant="outlined" startIcon={<ArrowLeftSharp/>}>Return to class Search</Button>    
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
         ):('')}
         </>
        );
    }
}

export default withStyles(styles)(SearchAndAddClasses);