import React, { PureComponent } from 'react';
import { TextField, Grid, Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Box } from '@material-ui/core';
import { WrapperContext } from './WrapperContext';
import { Delete, Publish} from '@material-ui/icons';
import WaitlistIcon from '../../../Layout/Waitlist.gif';
import ClosedIcon from '../../../Layout/Closed.gif';
import OpenIcon from '../../../Layout/Open.gif';

export default class ReviewClass extends PureComponent{
    static contextType = WrapperContext;

    render(){
        return(
            <>
            <Grid container spacing={2} direction="column" style={{ padding: 10 }}>
                <Grid item>
                    <Typography variant="subtitle1">
                    <b>Step 4 of 7: Review Class(es) Added To Request</b>
                    </Typography>
                </Grid>
            </Grid>
            <Grid>
            <Grid container spacing={2} style={{ padding: 10 }}>
                <Grid item>
                <Typography variant="body2" paragraph>
                                <strong>{this.context.cart.length} class(es)</strong> has been added to your enrollment approval request.
                                <br />
                                {this.context.cart.length < 5 && this.context.cart.length > 0 ? <span>You can add <strong>{5 - this.context.cart.length} more class(es)</strong> to this approval request. Please go to the previous step to add more classes to this request.</span>:this.context.cart.length === 0? 'Please go to the previous step to add a class to this request.': ''}
                </Typography>
                </Grid>
            </Grid>
            <Grid item>

            <TableContainer component={Paper} style={{ marginTop: 30 }}>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell>Upload Transcripts*</TableCell>
                        <TableCell>Comments</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.context.cart.map((x, index) => (
                        <React.Fragment key={x.classnbr}>
                        <TableRow>
                            <TableCell style={{padding: 20}}>
                            <Typography variant="subtitle1">
                                <b>{x.descr}</b>
                            </Typography>
                            <Typography variant="caption">
                                Class Number: {x.classnbr} 
                                <br />
                                Course: {x.subject}-{x.catalognbr}-{x.section}
                                <br/>
                                <Box display="flex" alignItems="center">
                                {x.enrlstatus === 'C' && x.waitlistmax === x.waitlisttotal ? (
                                <>  
                                    Status: 
                                    <img src={ClosedIcon} alt="close" />
                                    <Typography variant="caption"> Closed</Typography>
                                </>
                                ) : x.enrlstatus === 'C' && x.waitlisttotal < x.waitlistmax ? (
                                <>
                                    Status:
                                    <img src={WaitlistIcon} alt="waitlist" />
                                    <Typography variant="caption"> Wait List</Typography>
                                </>
                                ) : x.enrlstatus === 'O' ? (
                                <>
                                    Status:
                                    <img src={OpenIcon} alt="open" />
                                    <Typography variant="caption"> Open</Typography>
                                </>
                                ) : (
                                ''
                                )}
                                </Box>
                                Days & Times: {x.meeting}
                                <br />
                                Meeting Dates: {x.startdate}-{x.enddate}
                                <br />
                                Units: {x.unitsmax}
                                <br />
                                Instructor: {x.instructorname}
                            </Typography>
                            </TableCell>
                            <TableCell>
                                <input type="file" onChange={(e) => this.context.handleFileUpload(e, x.classnbr)}/><br/><br/>
                                {this.context.files && this.context.files[x.classnbr] ?  (
                                <>
                                    <Typography variant="caption"><b>File Uploaded:</b></Typography><br/>
                                    <Typography variant="caption">Name: {this.context.files[x.classnbr].name}</Typography><br/>
                                    <Typography variant="caption">Type: {this.context.files[x.classnbr].type}</Typography><br/>
                                    <Typography variant="caption">Size: {(this.context.files[x.classnbr].size / 1024).toFixed(2)} KB</Typography>
                                    {/*<Typography variant="caption">Description: {this.context.files[x.classnbr].description}</Typography>*/}
                                </>
                                ) : (
                                <Typography variant="caption">No file uploaded</Typography>
                                )}
                            </TableCell>
                            <TableCell>
                            <Typography variant="caption">
                                Add your comments for instructor and academic department about why you are requesting to add this course.
                            </Typography>
                            <TextField
                                key={x.classnbr}
                                fullWidth
                                multiline
                                rows={2}
                                variant="outlined"
                                placeholder="Comments"
                                margin="normal"
                                value={x.comment}
                                onChange={this.context.handleCommentChange(x.classnbr)}
                            />
                            </TableCell>
                            <TableCell>
                            <Button 
                                variant="contained"
                                color="inherit" 
                                size="small" 
                                onClick={()=> this.context.removeClass(x.classnbr)} 
                                startIcon={<Delete />}
                            >
                                Remove
                            </Button>
                            </TableCell>
                        </TableRow>

                        </React.Fragment>
                    ))}
                    <TableRow>
                    <TableRow>
                            <TableCell colSpan={2}>
                            <Typography variant="body2">
                                <b>Upload Transcripts:</b> If necessary or requested, you may upload copies of unofficial transcripts here demonstrating class prerequisites especially if completed outside of CSUF.
                            </Typography>
                            </TableCell>
                        </TableRow>
                    </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            </Grid>
        </Grid>
        </>
        );
    }
}

