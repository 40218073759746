import React, { PureComponent } from 'react';
import { Grid, Typography, CircularProgress} from '@material-ui/core';
import { WrapperContext } from './WrapperContext';
import { Alert} from '@material-ui/lab';

export default class Confirmation extends PureComponent{
    static contextType = WrapperContext; 
    componentDidMount() {
        if (!this.context.hasSubmittedRequest) {
            this.context.insertEnrollRequest();
            this.context.setHasSubmittedRequest(true); // Ensure this is tracked in the context
        }
    }
    render(){
        const Confirmation = () => (
            <>
            <div>
            <Grid item >
            <Typography variant="subtitle1"><b>Confirmation</b></Typography><br/>
            </Grid>
            <Grid item xs={12}>
            <Alert  severity="success">Your request for approval to enroll has been submitted successfully.</Alert>
            </Grid>
            <Grid item>
            <Typography variant="subtitle2">
                <br/>
                <strong>Tracking Code: {this.context.trackingCode}</strong>
                <br/><br/>
            </Typography> 
            </Grid>
            <Grid item>
             <Typography variant="body2">               
                An email with your tracking code has been sent to <b>{this.context.email}</b>.<br/><br/>
                Follow the directions in that email to track the status of the request and if approved, eventually register via the CSUF Portal under the Manage Classes title within the Student Home Page. Be sure to pay fees in full at the time of registration under the Financial Account tile to avoid enrollment cancelation.
            </Typography> 
            </Grid>
            </div>
            </>
          )

          const Loading = () =>(
            <>
            <Grid item>
               <Typography variant="h6">{this.context.loading && <CircularProgress color="#ffffff" size={20}/>}  Your submission is being processed...</Typography> 
            </Grid>
            <Grid item>
             <Typography variant="caption">
                    Please do not close this browser window until complete.
            </Typography> 
            </Grid>
            </>
          )

        return(
            <>
        <Grid container spacing={2} direction="column" style={{padding: 10}}>
            {this.context.loading? <Loading/>: <Confirmation/>}
        </Grid>
        </>
        )
    }
}