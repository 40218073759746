import React, { PureComponent } from 'react';
import { TextField, Grid, Typography } from '@material-ui/core';
import { WrapperContext } from './WrapperContext';


export default class CWID extends PureComponent{
    static contextType = WrapperContext;

    render(){
        return(
            <>
                <Grid container spacing={2} direction="column" style={{ padding: 10 }}>
                <Grid item>
                    <Typography variant="subtitle1">
                    <b>Step 1 of 7: Campus Wide Identification Number</b>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body2">
                    Be sure you either created a new student record or updated a prior student record to be assigned a registration appointment for the current semester (e.g., {this.context.activeTermName}).
                    </Typography>
                    <br/>
                </Grid>

                <Grid spacing={2} direction="column" style={{ padding: 10 }}>
                    <Grid item md={4} xs={12}>
                    <TextField
                        autoComplete="off"
                        fullWidth
                        size="medium"
                        name="cwid"
                        variant="outlined"
                        label="Enter CWID"
                        onChange={this.context.handleChange('cwid')}
                        defaultValue={this.context.cwid}
                        inputProps={{ maxLength: 9 }}
                        InputLabelProps={{ shrink: true }}
                    />
                    </Grid>

                    <Grid item xs={6}>
                    {this.context.errorcwid.length > 0 && (
                        <Typography variant="subtitle2" style={{ color: "#ff0000" }}>
                        {this.context.errorcwid}
                        </Typography>
                    )}
                    </Grid>
                    <br/>
                    <Grid item xs={12}>
                    <Typography variant="body2">
                        Go to{" "}
                        <a
                        href="https://cmsweb.fullerton.edu/psc/CFULPRD/EMPLOYEE/SA/c/CSU_SQA.CSU_SQA.GBL?page=csu_sqa_welcome&public"
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        New Student Creation or Returning Student Confirmation
                        </a> to assign an enrollment appointment (registration date).
                         
                    </Typography>
                    </Grid>

                    {/* Commented out sections can be added back if needed */}
                    {/* <Grid item xs={12}>
                    <Typography variant="caption">
                        If you forgot your CWID, please go to{" "}
                        <Link
                        href="https://my.fullerton.edu/Accounts/CWID/"
                        target="_blank"
                        rel="noopener"
                        underline="always"
                        variant="caption"
                        >
                        What's my CWID?
                        </Link>
                    </Typography>
                    </Grid>

                    <Grid item xs={12}>
                    <Typography variant="caption">
                        Please contact{" "}
                        <a href="mailto:iss@fullerton.edu" target="_blank">
                        iss@fullerton.edu
                        </a>
                        to confirm your CWID number if you do not remember it.
                    </Typography>
                    </Grid>

                    <Grid item xs={12}>
                    <Typography variant="caption">
                        If you need to create your CWID, please go to{" "}
                        <Link
                        href="https://extension.fullerton.edu/cwid"
                        target="_blank"
                        rel="noopener"
                        underline="always"
                        variant="caption"
                        >
                        Create my CWID
                        </Link>
                    </Typography>
                    </Grid> */}
                </Grid>
                </Grid>
        </>
        );
    }
}

