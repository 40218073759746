

import React, { PureComponent } from 'react';
import { Grid, Button, Container, Typography, Paper, Tabs, Tab, CircularProgress, AppBar, Toolbar, Divider, Switch, Breadcrumbs, Link, Box, Input } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Timeline, TimelineSeparator, TimelineConnector, TimelineDot, TimelineItem, TimelineContent } from '@material-ui/lab';
import Header from '../../../Layout/Header';
import Footer from '../../../Layout/Footer';
import { Alert, AlertTitle } from '@material-ui/lab';
import { NavigateNext } from '@material-ui/icons';

const styles = theme => ({

  paper: {
      marginTop: theme.spacing(4)
  }
});

//'CFE8FBB'
class Status extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      files: {},
      folderName:'ProfessionalDevelopment',
      accessToken:'VKfUFLs98E8AAAAAAAAAAbn5W5JOSwoEefzi5vSGTiQj27G4IVhq0IfNEiQ-UB1S',
      typeid: 0,
      firstname: '',
      lastname: '',
      cwid: '',
      referenceid: '',
      submitteddate: '',
      status: '',
      timeline: [],
      classes: [],
      cart:[],
      value: 0,
      loading: false,
      norecord: false,
      subtotal:0,
      consolidationfee: 29.11,
      titancardfee: 4.65,
      total:0,
      error:''
    };
  }

  Pay =id =>(e) =>{
    const classes = this.state.classes;
    const cart = this.state.cart;
    this.setState({error:''});
    const index = classes.findIndex(x => x.classnbr === id);

    if (e.target.checked)
    {
      this.setState((prevState,props) => ({subtotal: prevState.subtotal + classes[index].fee}));
      this.setState((prevState,props) => ({total: prevState.subtotal + prevState.consolidationfee + prevState.titancardfee,cart : [ ...this.state.cart, classes[index]]}));
      //cart: [ ...this.state.cart, classes[index]]
      //total: this.state.subtotal + this.state.consolidationfee + this.state.titancardfee + classes[index].fee
    }
    else
    {
      const newCart = cart.filter(x => x.classnbr !== id);
      this.setState({cart: newCart});

      this.setState((prevState,props) => ({subtotal: prevState.subtotal - classes[index].fee, cart : newCart}));
      if(this.state.cart.length === 1)
      {
        this.setState((prevState,props) => ({total: 0}));
      }
      else
      {
        this.setState((prevState,props) => ({total: prevState.subtotal + prevState.consolidationfee + prevState.titancardfee}));
      }
    }
  }

  handleFileUpload = (e, classnbr) => {
    const file = e.target.files[0];
    this.setState({
      files: { [classnbr]: file }
    });
   };


  uploadDocuments = (classNumber) => {
    try {
      this.setState({ loading: true });
      const file = this.state.files[classNumber];
      if (file) {
        const file = this.state.files[classNumber];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('classnumber', classNumber);
        formData.append('foldername', this.state.folderName);
        formData.append('referenceid', this.state.referenceid);

          fetch('https://extension.fullerton.edu/data/dropbox/uploadfile', {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': `Bearer ${this.state.accessToken}`
            // Note: 'Content-Type' should not be set when using FormData with fetch
          }
        });
        this.getdata(this.state.referenceid);
        }
        else{
          console.log(`No file found for class number ${classNumber}`);
        }
        this.setState({ loading: false });
      }catch (error) {
      console.error('Error uploading file', error);
      }
    }

  onSubmit = async() => {

    if(this.state.cart.length > 0)
    {
      this.setState({error:''});

        let requestBody = {
          regtype:'OU-FUL',
          referenceid:this.state.referenceid,
          cart: this.state.cart
        };
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
          body: JSON.stringify(requestBody)
        };
        //console.log("payrequest", JSON.stringify(requestBody));
        await fetch('https://extension.fullerton.edu/data/ou/pay', requestOptions)
        .then(response => response.json())
        .then(data =>{
            if (data)
                {
                  window.open("https://commerce.cashnet.com/cashnetg/selfserve/checkoutentrance.aspx?client=CSUF_PROD&SID=W027&f_name=" + this.state.firstname + "&l_name=" + this.state.lastname + "&CWID=" + this.state.cwid + "&CRSE_CODE=" + data + "&ITEMCODE=EW106&amount=$" + this.state.total + "&custcode=" + this.state.cwid + "&paymentcode=CC","_self");
                }
            })
        .catch(data => {alert(data);});
      }
      else
      {
        this.setState({error: "Please select an approved class."});
      }
  }

  getdata= async(id)=>{

    let requestBody = {
      referenceid: id
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(requestBody)
    };



    await fetch('https://extension.fullerton.edu/data/ou/track', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data) {
          if (data.cwid == null)
          {
            this.setState({norecord: true, loading: false});
          }
          else{
          this.setState({
            typeid: data.typeid,
            firstname: data.firstname,
            lastname: data.lastname,
            cwid: data.cwid,
            submitteddate: data.submitteddate,
            referenceid: data.referenceid,
            timeline: data.timeline,
            loading: false,
            status: data.status,
            classes: data.classes
          });
        }
        }
      })
      .catch(data => { 
        this.setState({norecord: true, loading: false});
      });
  }

  componentDidMount() {
    this.setState({loading: true});
    this.getdata(this.props.match.params.id);
  }

  render() {
    const handleChange = (event, newValue) => {
      this.setState({ value: newValue });
    };

    const getFileNameAfterLastDash = (text) => {
      const lastDashIndex = text.lastIndexOf('-');
      return lastDashIndex !== -1 ? text.substring(lastDashIndex + 1) : text;
    };
    //const url = "https://commerce.cashnet.com/cashnetg/selfserve/checkoutentrance.aspx?client=CSUF_PROD&SID=W027&f_name=nitin&l_name=agnihotri&CWID=891111111&CRSE_CODE=12345&ITEMCODE=EW106&amount=$1098.00&custcode=891111111&paymentcode=CC";
    const { classes} = this.props;
    return (
      <>
        <Header />
        <AppBar position="static">
                    <Toolbar variant="dense">
                        <Breadcrumbs aria-label="breadcrumb"  separator={<NavigateNext style={{ color: '#d1d1d1'}} fontSize="small" />}>         
                        <Link underline="hover" color="inherit" href="/">
                            <Typography variant="caption" style={{ color: '#d1d1d1'}}>Home</Typography>  
                        </Link>
                        <Typography variant="caption" style={{ color: '#ffffff'}}>Track</Typography>
                        </Breadcrumbs>
                    </Toolbar>
        </AppBar>
        <Container style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', marginTop: 20, maxWidth: 920}}>
        <Box alignItems="center" justifyContent="center" style={{ flexGrow: 1 }}>
        {this.state.loading === true?(<Grid container justify="center" className={classes.paper}>
                <Typography variant="h6">Loading... </Typography>{this.state.loading && <CircularProgress size={25}/>} 
            </Grid>): this.state.norecord === true?(
        <Grid container className={classes.paper}> 
        <Grid item xs={12}>
              <Alert severity="error">
              <AlertTitle>No Record Found</AlertTitle>
              Sorry, no enrollment request was found based on the tracking code you entered.
            </Alert>
        </Grid>
        </Grid>
            ) :( <>
      <AppBar elevation={0} position="static" style={{ background: "#ffffff", marginTop:18, border: '0.8px solid #ededed'}} >
          <Toolbar>
          <Typography style={{color: '#525252', flex: 1 }} variant="overline">{this.state.firstname} {this.state.lastname}</Typography> 
          <Typography style={{color: '#525252'}} variant="overline">Request: {this.state.referenceid}</Typography>    
          </Toolbar>
      </AppBar>

          <Grid item>
          <Paper>
            <Tabs value={this.state.value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
              <Tab label="Track Approvals" />
              {/*{this.state.typeid === 1?(
              <Tab label="Pay"/>
              ):''}*/}
            </Tabs>
          </Paper>
          </Grid>

          {this.state.value === 0 ? <>
          
          {/*{this.state.typeid === 1?(
          <Grid item>
            <Alert severity="info">
              If your request is fully approved, please click on “Pay” above to complete the request for enrollment.
            </Alert>
          </Grid>): ''}*/}

          <Timeline align="alternate" style={{marginTop: 80}}>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: '#32CD32' }} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="caption">Request: {this.state.referenceid}</Typography><br/>
              <Typography variant="caption">Submitted on: {this.state.submitteddate}</Typography>

            </TimelineContent>
          </TimelineItem>
          {this.state.timeline.map(x => (
          <TimelineItem key={x.id}>
              <TimelineSeparator>
                <TimelineDot style={{ backgroundColor:(x.status) === "Awaiting Approval"? "": (x.status) === "Denied"? "#FF0000": (x.status) === "Approved"? "#32CD32": ""}}/>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="caption"> {x.descr} ({x.subject}-{x.catalognbr}-{x.section})</Typography><br />
                <Typography variant="caption">Class: {x.classnbr}</Typography><br/>
                <Typography variant="caption"> <b>Approver</b>: {x.type}</Typography>
                <Typography variant="caption"> <b>Status</b>: {x.status}</Typography><br/>
                <Typography variant="caption">{x.status === "Pending Approval"? x.submitteddate: x.signeddate}</Typography><br />
                {x.fileuploaded === 'No file uploaded' && x.status === "Pending Approval"?
                <Typography variant="caption">Upload Transcript: <input type="file" style={{border: '1px solid #d5d7de'}} onChange={(e) => this.handleFileUpload(e, x.classnbr)} /><br/><Button variant="contained" size="small" color="primary" target="_blank" style={{marginTop: 5}} onClick={() => this.uploadDocuments(x.classnbr)}>Upload</Button></Typography>
                : x.fileuploaded !==null && x.fileuploaded !== 'No file uploaded'
                ?<Typography variant="caption">Transcript: {getFileNameAfterLastDash(x.fileuploaded)} <Button variant="contained" size="small" color="primary" target="_blank" href={x.filesharedlink}>View</Button></Typography>
                :<Typography variant="caption">Transcript: No file uploaded
                </Typography>}
                {x.waitlist === true && x.type === 'Department'?<Alert severity="warning"><Typography variant="caption">This course section shows an active wait-list-which is only for admitted students.  This request will not route to the Dept for review until Monday of week two of the semester, which is after wait-lists have expired.</Typography></Alert> : ''}
              </TimelineContent>              
            </TimelineItem>
              ))}
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor:(this.state.status) === "Completed"? "#32CD32":"" }}/>
            </TimelineSeparator>
            <TimelineContent><Typography variant="caption">{this.state.status}</Typography></TimelineContent>
          </TimelineItem>
          </Timeline></>
          : this.state.value === 1? <>
         <Grid container className={classes.paper}>
          <Typography variant="caption"><b>{this.state.classes.length} Approved Class(es)</b></Typography>
          </Grid>
          <Alert severity="info" style={{marginTop: 10}}>
            <Typography variant="caption">Conveniently pay online via CashNet, Cal State Fullerton's secure and approved electronic payment processor. Please note a 2.65% processing fee is added to all credit/debit card transactions.</Typography><br/><br/>
            <Typography variant="caption">There is no processing fee if you choose E-Check(ACH).</Typography><br/><br/>
            <Typography variant="caption">You will be redirected to the CashNet site. Once you have submitted your payment information, you will return to our site.</Typography><br/><br/>
            <Typography variant="caption">Click on pay in upper right corner.</Typography>
          </Alert>
          {this.state.classes.map(x => (<>
            <Grid key={x.lineid} style={{marginTop: 10, padding: 5, backgroundColor: '#f8f8f8'}} >
            <Grid item container justify="space-between" style={{alignItems: "center"}} > 
              <Grid item>
              <Typography variant="subtitle1"> {x.descr}</Typography>
              </Grid>
              <Grid item>
                <Switch color="primary" disabled={(x.paymentstatus === "Completed" || x.paymentstatus === "Awaiting Authorization")? true: false} onChange={this.Pay(x.classnbr)} name="checkedA" inputProps={{ 'aria-label': 'primary checkbox' }}/> Pay
              </Grid>
            </Grid>          
            <Grid item>
            <Typography variant="caption" paragraph>
                Class: {x.classnbr}<br/>
                Section: {x.subject}-{x.catalognbr}-{x.section}<br/>
                Days and Times: {x.meeting} <br/>
                Meeting Dates: {x.startdate}-{x.enddate}<br/>
                Units: {x.unitsmax}<br/>
                Instructor: {x.instructorname}
            </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">Payment Status: {x.paymentstatus}</Typography>
            </Grid>
            <Grid item>
            <Typography variant="caption">
                <strong>Fee: ${x.fee}.00</strong>
            </Typography>
            </Grid>
            </Grid>
          </>
        ))}
        <Grid container style={{padding: 5, marginTop: 10}}>
        <Grid item xs={6}>
        <Typography variant="caption">For more information on Fee, please refer to: <br/> <a href="https://extension.fullerton.edu/ou/fees-resources.aspx" target="_blank" rel="noopener noreferrer" >Open University Fee-Resources</a></Typography><br/> 
        </Grid>
        <Grid item container justify="space-between" xs={6}>
            <Grid item xs={6}>
            <Typography variant="caption">Sub Total</Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography variant="caption" style={{float: 'right'}}> ${this.state.subtotal}</Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography variant="caption">Consolidation Fee</Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography variant="caption" style={{float: 'right'}}> ${this.state.consolidationfee}</Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography variant="caption">Titan Card Fee</Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography variant="caption"  style={{float: 'right'}}> ${this.state.titancardfee}</Typography>
            </Grid>
            <Grid item xs={12}>
            <Divider/>
            </Grid>
            <Grid item xs={6}>
            <Typography variant="caption">Estimated Total</Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography variant="caption"  style={{float: 'right'}}> ${this.state.total.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={12}>
            <br/>
                <Button fullWidth variant="contained" onClick={this.onSubmit} color="primary" size="large">Proceed To CashNet</Button>
                {this.state.error.length > 0 && <Typography variant="caption" style={{color: "#ff0000"}}>{this.state.error}</Typography>}
            </Grid>
        </Grid>
    </Grid>

    <div style={{ marginTop: 50}}>
    </div></>
        : ""
        }

          </>)}
        </Box>
        </Container>
        <Footer/>
      </>
    )
  }
}


export default withStyles(styles)(Status);