import React from 'react';
import { AppBar, Toolbar} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Logo from './logo-extension-rev-orange.png';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export default function Header() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
          <AppBar position="static" style={{backgroundColor:'#00274C',  height: 50}}>
                <Toolbar variant="dense">
                <img src={Logo} alt="Logo" style={{width: 220, height: 25, maxWidth: '100%', maxHeight: '100%'}}/>
                </Toolbar>
            </AppBar>
        </div>
        /*<Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg">
            
                <AppBar position="static" color="transparent" elevation={0}>
                    <Toolbar>
                        <img src={Logo} alt="Logo" style={{ height: 60, width: 210}} />
                    </Toolbar>
                </AppBar>
            
        </Container>
    </Box>*/
    );
}
