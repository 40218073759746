import React, { PureComponent } from 'react';
import { Grid, Typography, Card, Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core';
import { WrapperContext } from './WrapperContext';



export default class Review extends PureComponent{
    static contextType = WrapperContext; 
    render(){

        const ques4 = () => (
            <p>
            {this.context.ques4} <strong>{this.context.resp4? 'Yes': 'No'} </strong><br/>
            </p>
        )
        const ques6 = () => (
            <p>
            {this.context.ques6} <strong>{this.context.resp6? 'Yes': 'No'} </strong><br/>
            </p>
        )
        const ques8 = () => (
            <p>
            {this.context.ques8} <strong>{this.context.resp8? 'Yes': 'No'} </strong><br/>
            </p>
        )

        return(
            <>
        <Grid container spacing={2} direction="column" style={{ padding: 10 }}>
                <Grid item>
                    <Typography variant="subtitle1">
                    <b>Step 7 of 7: Review and Submit Enrollment Approval Request</b>
                    </Typography>
                </Grid>
        </Grid>
        <Grid container spacing={2} direction="column" style={{padding: 10}}>
              
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2"><b>Student Information</b></Typography>
              </Grid>
              
              <div style={{ padding: 20 }}>
        {/* Personal Information Card */}
          <Card variant="outlined" style={{ padding: 20, marginBottom: 20 }}>
          <TableContainer component={Paper} style={{ boxShadow: 'none', border: 'none' }}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell style={{ border: 'none' }}><strong>CWID:</strong></TableCell>
                  <TableCell style={{ border: 'none' }}>{this.context.cwid}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}><strong>First Name:</strong></TableCell>
                  <TableCell style={{ border: 'none' }}>{this.context.firstname}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}><strong>Last Name:</strong></TableCell>
                  <TableCell style={{ border: 'none' }}>{this.context.lastname}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}><strong>Address Line 1:</strong></TableCell>
                  <TableCell style={{ border: 'none' }}>{this.context.address1}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}><strong>Address Line 2:</strong></TableCell>
                  <TableCell style={{ border: 'none' }}>{this.context.address2}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}><strong>City:</strong></TableCell>
                  <TableCell style={{ border: 'none' }}>{this.context.city}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}><strong>State/Province:</strong></TableCell>
                  <TableCell style={{ border: 'none' }}>{this.context.state}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}><strong>Zip/Postal Code:</strong></TableCell>
                  <TableCell style={{ border: 'none' }}>{this.context.zip}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}><strong>Country:</strong></TableCell>
                  <TableCell style={{ border: 'none' }}>{this.context.country}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}><strong>Email:</strong></TableCell>
                  <TableCell style={{ border: 'none' }}>{this.context.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}><strong>Birthdate:</strong></TableCell>
                  <TableCell style={{ border: 'none' }}>{this.context.birthdate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}><strong>Gender:</strong></TableCell>
                  <TableCell style={{ border: 'none' }}>{this.context.gender}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}><strong>Home Phone:</strong></TableCell>
                  <TableCell style={{ border: 'none' }}>{this.context.phonehome}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}><strong>Mobile Phone:</strong></TableCell>
                  <TableCell style={{ border: 'none' }}>{this.context.phonemobile}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        </div>
              
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2"><b>Eligibility Information</b></Typography>
              </Grid>
              <div style={{ padding: 20 }}>
              <Card variant="outlined" style={{ padding: 20, marginBottom: 20 }}>
              <TableContainer component={Paper} style={{ boxShadow: 'none', border: 'none' }}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell style={{ border: 'none' }}>{this.context.ques1}</TableCell>
                    <TableCell style={{ border: 'none' }}><strong>{this.context.resp1 === '1' ? 'Yes' : 'No'}</strong></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ border: 'none' }}>{this.context.ques2}</TableCell>
                    <TableCell style={{ border: 'none' }}><strong>{this.context.resp2 === '1' ? 'Yes' : 'No'}</strong></TableCell>
                  </TableRow>
                  {this.context.resp2 === '1' ?<>
                  <TableRow>
                    <TableCell style={{ border: 'none' }}>{this.context.ques11}</TableCell>
                    <TableCell style={{ border: 'none' }}><strong>{this.context.resp11 === '1' ? 'Yes' : 'No'}</strong></TableCell>
                  </TableRow>
                  </>: ''}
                  <TableRow>
                    <TableCell style={{ border: 'none' }}>{this.context.ques3}</TableCell>
                    <TableCell style={{ border: 'none' }}><strong>{this.context.resp3 === '1' ? 'Yes' : 'No'}</strong></TableCell>
                  </TableRow>
                  {this.context.resp3 === '0' ?<>
                  <TableRow>
                    <TableCell style={{ border: 'none' }}>{this.context.ques4}</TableCell>
                    <TableCell style={{ border: 'none' }}><strong>{this.context.resp4 === '1' ? 'Yes' : 'No'}</strong></TableCell>
                  </TableRow>
                  </>: ''}
                  <TableRow>
                    <TableCell style={{ border: 'none' }}>{this.context.ques9}</TableCell>
                    <TableCell style={{ border: 'none' }}><strong>{this.context.resp9 === '1' ? 'Yes' : 'No'}</strong></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ border: 'none' }}>{this.context.ques10}</TableCell>
                    <TableCell style={{ border: 'none' }}><strong>{this.context.resp10 === '1' ? 'Yes' : 'No'}</strong></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              </TableContainer>
              </Card>
              </div>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2"><b>Class(es) added to your approval request to enroll</b></Typography>
              </Grid>
              <div style={{ padding: 20 }}>
              <Grid container spacing={2}>
                {this.context.cart.map((x) => (
                  <Grid item xs={12} sm={6} md={4} key={x.classnbr}>
                    <Card variant="outlined" style={{ padding: 20, marginBottom: 20 }}>
                    <Typography variant="subtitle1" gutterBottom><b>{x.descr}</b></Typography>
                    <TableContainer component={Paper} style={{ boxShadow: 'none', border: 'none' }}>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ border: 'none' }}><b>Class:</b></TableCell>
                          <TableCell style={{ border: 'none' }}>{x.classnbr}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: 'none' }}><b>Section:</b></TableCell>
                          <TableCell style={{ border: 'none' }}>{x.subject}-{x.catalognbr}-{x.section}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: 'none' }}><b>Days and Times</b></TableCell>
                          <TableCell style={{ border: 'none' }}>{x.meeting}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: 'none' }}><b>Meeting Dates:</b></TableCell>
                          <TableCell style={{ border: 'none' }}>{x.startdate}-{x.enddate}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: 'none' }}><b>Instructor:</b></TableCell>
                          <TableCell style={{ border: 'none' }}>{x.instructorname}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: 'none' }}><b>Comment:</b></TableCell>
                          <TableCell style={{ border: 'none' }}>{x.comment}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><b>Files Uploaded:</b></TableCell>
                          <TableCell>
                          {this.context.files && this.context.files[x.classnbr] ?  (
                                <>
                                    <Typography variant="caption"><b>File Uploaded:</b></Typography><br/>
                                    <Typography variant="caption">Name: {this.context.files[x.classnbr].name}</Typography><br/>
                                    <Typography variant="caption">Type: {this.context.files[x.classnbr].type}</Typography><br/>
                                    <Typography variant="caption">Size: {(this.context.files[x.classnbr].size / 1024).toFixed(2)} KB</Typography>
                                    {/*<Typography variant="caption">Description: {this.context.files[x.classnbr].description}</Typography>*/}
                                </>
                          ) : (
                            <Typography variant="caption">No file uploaded</Typography>
                          )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    </TableContainer>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              </div>
            </Grid>
            <br/><br/>
          </>
        )
    }
}