

import React, { PureComponent } from 'react';
import { Grid, Button, Link, AppBar, Toolbar, Breadcrumbs, Typography, Stepper, Step, StepLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CWID from './CWID';
import Eligibilty from './Eligibility';
import SearchAndAddClasses from './SearchAndAddClasses';
import TermsofService from './TermsofService';
import Review from './Review';
import StudentInformation from './StudentInformation';
import ReviewClass from './ReviewClass';
import Confirmation from './Confirmation';
import { WrapperContext } from './WrapperContext';
import { NavigateNext, NavigateBefore, Publish } from '@material-ui/icons';
import Header from '../../../Layout/Header';
import Footer from '../../../Layout/Footer';
import { Alert } from '@material-ui/lab';
//import axios from 'axios';


const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

const validCWIDRegex = RegExp(
  /^\d{9}$/
);

const validnumbersonlyRegex = RegExp(
  /^\d+$/
);

const validlettersandspacesonlyRegex = RegExp(
  /^[a-zA-Z\s]*$/
);

//const validateForm = errors => {
//  let valid = true;
//  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
//  return valid;
//};

//const validFirstNameRegex = RegExp(
//  /^[a-zA-Z]+$/
//);

const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepIcon: {
    color: '#ccc',
    '&$active': {
      color: 'primary',
    },
    '&$completed': {
      color: '#29C166',
    },
  },
  active: {},
  completed: {},
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },

});

const delay = async (ms) => {
    return new Promise((resolve) => 
        setTimeout(resolve, ms));
};

class Wrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      files: {},
      folderName:'ProfessionalDevelopment',
      accessToken:'VKfUFLs98E8AAAAAAAAAAbn5W5JOSwoEefzi5vSGTiQj27G4IVhq0IfNEiQ-UB1S',
      activeStep: 0,
      cwid: '',
      errorcwid: '',
      showcreatecwid: false,
      showlookupcwid: false,
      firstname: '',
      errorfirstname: '',
      lastname: '',
      errorlastname: '',
      email: '',
      erroremail: '',
      confirmemail: '',
      errorconfirmemail: '',
      nid: '',
      errornid: '',
      birthdate: '',
      errorbirthdate: '',
      gender: '',
      errorgender: '',
      address1: '',
      erroraddress1: '',
      address2: '',
      city: '',
      errorcity: '',
      state: '',
      errorstate: '',
      zip: '',
      errorzip: '',
      country: '',
      errorcountry: '',
      phonehome: '',
      errorphonehome: '',
      phonemobile: '',
      ques1: 'Are you officially admitted to a degree or eligible post-baccalaureate program at CSUF and currently attending CSUF as an admitted student?',
      resp1: '',
      errorresp1: '',
      ques2: 'Are you a current high school student?',
      resp2: '',
      errorresp2: '',
      ques3: 'Did you attend, for at least three years, a high school or college in which English was the principal language of instruction?',
      resp3: '',
      errorresp3: '',
      ques4: 'Do you have documentation of a TOEFL score of 500 or higher, or iBT TOEFL score of 61 or higher, or IELTS score of 5.5 or higher, or PTE (Pearson’s English Language Test) score of 43 or higher?',
      resp4: '',
      errorresp4: '',
      ques5: 'Have you lived in the U.S. for the preceding three years?',
      resp5: '',
      errorresp5: '',
      ques6: 'Do you have a Tuberculosis test verification?',
      resp6: '',
      errorresp6: '',
      ques7: 'Were you born before January 1, 1957?',
      resp7: '',
      errorresp7: '',
      ques8: 'Have you been immunized against measles and rubella?',
      resp8: '',
      errorresp8: '',
      ques9: 'Are you a U.S. Citizen?',
      resp9: '',
      errorresp9: '',
      ques10: 'Have you addressed all registration and academic HOLDS in the Tasks Tile of your student homepage?',
      resp10: '',
      errorresp10: '',
      ques11: 'If yes, have you consulted with an Open University Advisor and have permission to submit this request for approval and enrollment?',
      resp11: '',
      errorresp11: '',
      showques4: false,
      showques6: false,
      showques8: false,
      showques11: false,
      showImmigration: false,
      showError: false,
      errorMessage: '',
      immigrationStatus: '',
      errorimmigrationStatus: '',
      residentcardNbr: '',
      errorresidentcardNbr: '',
      I94admissionNbr: '',
      errorI94admissionNbr: '',
      admissionNbr: '',
      erroradmissionNbr: '',
      visatype: '',
      errorvisatype: '',
      passportNbr: '',
      errorpassportNbr: '',
      other: '',
      errorother: '',
      reason: '',
      errorreason: '',
      reasonreentry: '',
      errorreasonreentry: '',
      reasonCollege: '',
      errorreasonCollege: '',
      reasonOther: '',
      errorreasonOther: '',
      cart: [],
      class:[],
      errorclassnbr: '',
      loading: false,
      anchorEl: null,
      placement: '',
      openPopper: false,
      trackingCode: '',
      subtotal: 0,
      consolidationfee: 29.11,
      titancardfee: 4.65,
      total: 0,
      openModal : false,
      errorModal: '',
      activeTermName: '',
      activeTermValue:'',
      searchterm: '',
      searchvalue: '',
      searchResult: [],
      groupedsearchResult: [],
      selectedRowDetails: [],
      showsearchResult: false,
      showsearch: false,
      showsearchDetails: false,
      searchSelectedCourse: '',
      searchSelectedCourseDescr: '',
      showclassAdded: false,
      isUploadInitiated: false,
      hasSubmittedRequest: false
    };

    this.baseState = this.state;

  }

  handleFileUpload = (e, classnbr) => {
    const file = e.target.files[0];
    this.setState(prevState => ({
      files: { ...prevState.files, [classnbr]: file }
    })
  )};

    //, ()=>{ this.uploadDocuments()});

  uploadDocuments = async() => {

  // Check if there are any files to upload
  if (!this.state.files || Object.keys(this.state.files).length === 0) {
    console.log('No files to upload.');
    this.setState({ loading: false });
    return;
  }
    try {
      for (const classNumber in this.state.files) {
        const file = this.state.files[classNumber];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('classnumber', classNumber);
        formData.append('foldername', this.state.folderName);
        formData.append('referenceid', this.state.trackingCode);

        await fetch('https://extension.fullerton.edu/data/dropbox/uploadfile', {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': `Bearer ${this.state.accessToken}`
            // Note: 'Content-Type' should not be set when using FormData with fetch
          }
        });
        }
      this.setState({ loading: false });
    } catch (error) {
      console.error('Error uploading file', error);
    }
  };


  insertEnrollRequest = async() => {

    this.setState({ loading: true });

    let request = {
      cwid: this.state.cwid,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      gender: this.state.gender,
      birthdate: this.state.birthdate,
      phonehome: this.state.phonehome,
      phonemobile: this.state.phonemobile,
      address1: this.state.address1,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      country: this.state.country,
      cart: this.state.cart,
      resp1: this.state.resp1,
      resp2: this.state.resp2,
      resp3: this.state.resp3,
      resp4: this.state.resp4,
      resp5: this.state.resp5,
      resp6: this.state.resp6,
      resp7: this.state.resp7,
      resp8: this.state.resp8,
      resp9: this.state.resp9,
      resp10: this.state.resp10,
      resp11: this.state.resp11,
      immigrationStatus: this.state.immigrationStatus,
      residentcardNbr: this.state.residentcardNbr,
      I94admissionNbr: this.state.I94admissionNbr,
      admissionNbr: this.state.admissionNbr,
      visatype: this.state.visatype,
      passportNbr: this.state.passportNbr,
      other: this.state.other,
      reason: this.state.reason,
      reasonCollege: this.state.reasonCollege,
      reasonOther: this.state.reasonOther
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(request)
    };
    //console.log(JSON.stringify(request))
    await fetch('https://extension.fullerton.edu/data/ou/enrollrequest', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data) {

          this.setState({ trackingCode: data }, () => {
            if (!this.state.isUploadInitiated) {
              this.uploadDocuments();
              this.setState({ isUploadInitiated: true }); // Add a flag to prevent re-invoking
            }
          });
          //upload files
          //if(Object.keys(this.state.files).length > 0)
          //{
          //  if (callback && typeof callback === 'function') {
          //    callback(); 
          //  }
          //}
        }
      })
      .catch(data => { alert(data); });

    };
  

  checkduplicate = (data) => {
    data['cwid'] = this.state.cwid;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(data)
    };
    //alert(JSON.stringify(data));
    return fetch('https://extension.fullerton.edu/data/ou/DuplicateCheck', requestOptions)
      .then((response) => response.json())
      .then((responseData) => {
        return responseData;
      })
  };

  updateCart = (newCartitem) =>{
    //append to cart
    this.setState({ cart: [...this.state.cart, ...newCartitem]});
    this.setState({ class: [] });

  }

  handlebackClassSearch =() =>{
    this.setState({showsearch: true,showsearchResult: false, showclassAdded: false, searchvalue: ''});
  }

  handlebackClassSearchResults=()=>{
    this.setState({showsearchResult: true, showsearchDetails: false});
  }

  setSearchTerm = (term) =>{
    this.setState({searchterm: term, showsearch: true});
    
  }

  setSearchValue =(value) =>{
    this.setState({searchvalue: value})
  }

  setHasSubmittedRequest=(value) =>{
    this.setState({hasSubmittedRequest: value})
  }

  componentDidMount() {
    this.getActiveTerm();

  }

  getActiveTerm =async()=>{
    this.setState({ loading: true });
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
      };
      await fetch('https://extension.fullerton.edu/data/ou/getactiveterm', requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json().then(error => {
              throw new Error(error.message);
            });
            
          }
        })
        .then(data => {
          if (data) {
            this.setState({activeTermName: data.name, activeTermValue: data.value, loading: false })
          }
        })
        .catch((error) => {
            // Your error is here!
            alert(error.message);
            this.setState({ loading:false });
          });
  }

 search = async() =>{
    this.setState({ loading: true });
    await delay(2000);
    let request = {
        searchvalue: this.state.searchvalue,
        searchterm: this.state.searchterm
      };
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify(request)
      };
      //console.log(JSON.stringify(request))
      await fetch('https://extension.fullerton.edu/data/ou/searchclass', requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data) {
            // this.setState(this.baseState);
            this.setState({ searchResult: data, loading: false, showsearchResult: true, showsearch: false }, ()=>{console.log(JSON.stringify(this.state.searchResult))});
            this.processResults(data,this.handleProcessedResults);

          }
        })
        .catch(data => { alert(data); });

 }

    showRowDetails = (rowDetails,course,descr) => {
        this.setState({ 
            selectedRowDetails: rowDetails, 
            searchSelectedCourse: course,
            searchSelectedCourseDescr: descr, 
            showsearchDetails: true, 
            showsearchResult: false 
        });
    };


    processResults = (data, callback) => {
        const groupedData = new Map();

        data.forEach(item => {
        const key = `${item.subject} ${item.catalognbr}`;
        if (!groupedData.has(key)) {
            groupedData.set(key, { descr: item.descr, rows: [] });
        }
        groupedData.get(key).rows.push(item);
        });

        const resultArray = Array.from(groupedData, ([key, value]) => ({
        subjectCatalogNbr: key,
        descr: value.descr,
        rowCount: value.rows.length,
        rows: value.rows
        }));

        if (callback) {
            callback(resultArray);
        }
    
        return resultArray;

  };

  handleProcessedResults = (processedData) => {
    console.log('Processed Data:', processedData);
    this.setState({groupedsearchResult: processedData});
    // Perform any additional actions with the processed data
  };

  addClass= async(data) =>{
    this.setState({loading: true});
    const result = await this.checkduplicate(data);
    if (result != null) {
      if (result > 0) {
        this.setState({loading:false, openModal: true, errorModal: 'You have already submitted an approval request for this specific course section and it may already be approved or remain pending, please consider submitting a request for approval for a different section of this course or a different course. Contact EIP Student Services at <a href="mailto:eipconnect@fullerton.edu" target="_blank">eipconnect@fullerton.edu</a> with any questions.' })
      }
      else{
        const initialArr = Array.isArray(data) ? data : [data];
        const index = this.state.cart.findIndex(x => x.subject === initialArr[0].subject && x.catalognbr === initialArr[0].catalognbr);
        if (index > -1) {
          this.setState({ loading:false, openModal: true, errorModal: 'You have placed more than one section of the same course in this transaction. You may only select one section of a specific course in the same transaction. If denied approval to enroll in the one selected section of a course, you can submit a separate request later for another section of that select course.' });
        }
        else if(initialArr[0].classtype === "N"){
          this.setState({ loading:false, openModal: true, errorModal: 'You have selected a non-enroll class number for a combined-section course. Please return to class search and only input the class number for the lecture/seminar portion of this combined coursed section.' });
        }
        //else if (initialArr[0].term.slice(-1) === '')
        else{
          this.setState({ errorclassnbr: '' });
          const newArr = initialArr.map(x => ({ ...x, comment: '' }));
          //fee calculation
          this.setState({ subtotal: this.state.subtotal + initialArr[0].fee })
          this.setState({ total: this.state.subtotal + this.state.consolidationfee + this.state.titancardfee })

          // this.setState({...this.state.cart, ...data});
          this.setState({ cart: [...this.state.cart, ...newArr], loading: false });
          this.setState({class: newArr, loading: false, showclassAdded: true, showsearchDetails:false})
        }

      }
    }
    //cart: [...x.cart, {id: 4, value: 0}]
    //this.setState(prevState => ({cart: [...prevState.cart, data]}));
    }
    

  searchClass = async (data, callback) => {
    this.setState({ loading: true });
    const result = await this.checkduplicate(data);
    if (result != null) {
      if (result === 1) {
        this.setState({loading:false, openModal: true, errorModal: 'You have already submitted an approval request for this specific course section and it may already be approved or remain pending, please consider submitting a request for approval for a different section of this course or a different course. Contact EIP Student Services at <a href="mailto:eipconnect@fullerton.edu" target="_blank">eipconnect@fullerton.edu</a> with any questions.' })
      }
      else if(result === 2){
        this.setState({loading:false, openModal: true, errorModal: 'You have already submitted an approval request for another section of the same course in this transaction. You may only select one section of a specific course in the same transaction. If denied approval to enroll in the one selected section of a course, you can submit a separate request later for another section of that select course.' })
      }
      else {
        // https://cors-anywhere.herokuapp.com/ https://extension.fullerton.edu/data/ou/getclass
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
          body: JSON.stringify(data)
        };
        await fetch('https://extension.fullerton.edu/data/ou/getclass', requestOptions)
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              return response.json().then(error => {
                throw new Error(error.message);
              });
              
            }
          })
          .then(data => {
            if (data) {
              const initialArr = data;

              const index = this.state.cart.findIndex(x => x.subject === initialArr[0].subject && x.catalognbr === initialArr[0].catalognbr);
              if (index > -1) {
                this.setState({ errorclassnbr: 'You have placed more than one section of the same course in this transaction. You may only select one section of a specific course in the same transaction. If denied approval to enroll in the one selected section of a course, you can submit a separate request later for another section of that select course.', loading: false });
              }
              else if(initialArr[0].classtype === "N"){
                this.setState({ errorclassnbr: 'You have selected a non-enroll class number for a combined-section course. Please return to class search and only input the class number for the lecture/seminar portion of this combined coursed section.', loading:false });
              }
              //else if (initialArr[0].term.slice(-1) === '')
              else{
                this.setState({ errorclassnbr: '' });
                const newArr = initialArr.map(x => ({ ...x, comment: '' }));
                //fee calculation
                this.setState({ subtotal: this.state.subtotal + initialArr[0].fee })
                this.setState({ total: this.state.subtotal + this.state.consolidationfee + this.state.titancardfee })

                // this.setState({...this.state.cart, ...data});
                //this.setState({ cart: [...this.state.cart, ...newArr], loading: false });
                this.setState({class: newArr, loading: false})
              }

            }
            if (callback) callback();
          })
          .catch((error) => {
            // Your error is here!
            alert(error.message);
            this.setState({ errorclassnbr: 'Class not found.', loading:false });
            if (callback) callback();
          });
      }
    }
  };
  


  handleChangeCart = (id, e) => {
    const index = this.state.cart.findIndex(x => x.classnbr === id);
    const _class = Object.assign({}, this.state.cart[index]);
    _class.comment = e.target.value;
    const newCart = Object.assign([], this.state.cart);
    newCart[index] = _class;
    this.setState({ cart: newCart });
  }

  handleCommentChange = (id) => (event) => {
    this.handleChangeCart(id, event);
  };

  removeClass = (id) => {

    const cart = this.state.cart;

    //fee calculation
    const index = cart.findIndex(x => x.classnbr === id);
    this.setState({ subtotal: this.state.subtotal - cart[index].fee });
    if ((this.state.subtotal - cart[index].fee) === 0) {
      this.setState({ total: 0 });
    }
    else {
      this.setState({ total: this.state.total - cart[index].fee });
    }

    //remove class
    const newCart = cart.filter(x => x.classnbr !== id);
    this.setState({ cart: newCart });

    this.setState(prevState => {
      const updatedFiles = { ...prevState.files };
      delete updatedFiles[id];
      return { files: updatedFiles };
    });

  }

  // Proceed to next step
  handleNext = () => {

    let stepValid = true;
    const { activeStep } = this.state;
    //alert("activestep" + activeStep);
    switch (activeStep) {
      case 0:
        if (this.state.cwid === '') {
          this.setState({ errorcwid: 'CWID is required.' });
          stepValid = false;
        } else if (this.state.errorcwid.length > 0) { stepValid = false; }
        break;
      case 1:
        if (this.state.resp1 === '') { this.setState({ errorresp1: 'An answer is required.' }); stepValid = false; } else if (this.state.resp1 === 1) { this.setState({ errorresp1: this.geterrorMessage(1) }); stepValid = false; }
        if (this.state.resp2 === '') { this.setState({ errorresp2: 'An answer is required.' }); stepValid = false; }
        if (this.state.resp3 === '') { this.setState({ errorresp3: 'An answer is required.' }); stepValid = false; }
        if (this.state.resp3 === 0) { if (this.state.resp4 === '') { this.setState({ errorresp4: 'An answer is required.' }); stepValid = false; } else if (this.state.resp4 === 0) { this.setState({ errorresp4: this.geterrorMessage(4) }); stepValid = false; } }
        //if (this.state.resp5 === '') { this.setState({ errorresp5: 'An answer is required.' }); stepValid = false; }
        //if (this.state.resp5 === 0) { if (this.state.resp6 === '') { this.setState({ errorresp6: 'An answer is required.' }); stepValid = false; } else if (this.state.resp6 === 0) { this.setState({ errorresp6: this.geterrorMessage(5) }); stepValid = false; } }
        //if (this.state.resp7 === '') { this.setState({ errorresp7: 'An answer is required.' }); stepValid = false; }
        //if (this.state.resp7 === 0) { if (this.state.resp8 === '') { this.setState({ errorresp8: 'An answer is required.' }); stepValid = false; } else if (this.state.resp8 === 0) { this.setState({ errorresp8: this.geterrorMessage(5) }); stepValid = false; } }
        if (this.state.resp9 === '') { this.setState({ errorresp9: 'An answer is required.' }); stepValid = false; }
        if (this.state.resp10 === '') { this.setState({ errorresp10: 'An answer is required.' }); stepValid = false; } else if (this.state.resp10 === 0) { this.setState({ errorresp10: this.geterrorMessage(3) }); stepValid = false; }
        if (this.state.resp2 === 1) { if (this.state.resp11 === '') { this.setState({ errorresp11: 'An answer is required.' }); stepValid = false; } else if (this.state.resp11 === 0) { this.setState({ errorresp11: this.geterrorMessage(2) }); stepValid = false; } }
        if (this.state.resp9 === 0) {
          if (this.state.immigrationStatus === '') {
            this.setState({ errorimmigrationStatus: 'Immigration Status is required.' });
            stepValid = false;
          }
          else if (this.state.immigrationStatus === 1) {
            if (this.state.residentcardNbr === '') {
              this.setState({ errorresidentcardNbr: 'I-551 Alien Resident Card Number is required.' });
              stepValid = false;
            }

          }
          else if (this.state.immigrationStatus === 2) {
            if (this.state.I94admissionNbr === '') {
              this.setState({ errorI94admissionNbr: 'I-94 Admission Number is required.' });
              stepValid = false;
            }
          }
          else if (this.state.immigrationStatus === 3) {
            if (this.state.visatype === '') { this.setState({ errorvisatype: 'Visa Type is required.' }); stepValid = false; }
            if (this.state.passportNbr === '') { this.setState({ errorpassportNbr: 'Passport Number is required.' }); stepValid = false; }
            if (this.state.admissionNbr === '') { this.setState({ erroradmissionNbr: 'Admission Number is required.' }); stepValid = false; }
          }
          else if (this.state.immigrationStatus === 5) {
            if (this.state.other === '') { this.setState({ errorother: 'Other is required.' }); stepValid = false; }
          }

        }
        else if (this.state.resp9 === 1) {
          this.setState({ errorimmigrationStatus: '' });
        }

        break;
      case 2:
        if (this.state.cart.length === 0) { stepValid = false; }
        break;
      case 3:
        if (this.state.cart.length === 0) { stepValid = false; }
        break;
      case 4:
        if (this.state.firstname === '') { this.setState({ errorfirstname: 'First Name is required.' }); stepValid = false; }
        if (this.state.lastname === '') { this.setState({ errorlastname: 'Last Name is required.' }); stepValid = false; }
        if (this.state.email === '') { this.setState({ erroremail: 'Email is required.' }); stepValid = false; }
        if (this.state.phonehome === '') { this.setState({ errorphonehome: 'Home Phone is required.' }); stepValid = false; }
        if (this.state.birthdate === '') { this.setState({ errorbirthdate: 'Birthdate is required.' }); stepValid = false; }
        if (this.state.gender === '') { this.setState({ errorgender: 'Gender is required.' }); stepValid = false; }
        if (this.state.address1 === '') { this.setState({ erroraddress1: 'Address Line 1 is required.' }); stepValid = false; }
        if (this.state.city === '') { this.setState({ errorcity: 'City is required.' }); stepValid = false; }
        if (this.state.state === '') { this.setState({ errorstate: 'State is required.' }); stepValid = false; }
        if (this.state.zip === '') { this.setState({ errorzip: 'Zip is required.' }); stepValid = false; }
        if (this.state.country === '') { this.setState({ errorcountry: 'Country is required.' }); stepValid = false; }
        if (this.state.reason === '') { this.setState({ errorreason: 'An answer is required.' }); stepValid = false; }
        if (this.state.reason === 1 && this.state.reasonreentry === '') { this.setState({ errorreasonreentry: 'An answer is required.' }); stepValid = false; }
        break;
      default:
        break;
    }
    //alert(this.state.errorcwid);
    //alert(this.stepValid(activeStep));
    //this.setState({activeStep: activeStep + 1});
    if (stepValid) {
      this.setState({ activeStep: activeStep + 1 });
    }
    else {
      this.setState({ activeStep: activeStep });
    }

  }

  // Back to previous step
  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1
    });
  }

  showLoading = () => {
    this.setState({ loading: true });
  }

  onCloseModal = ()=>{
    this.setState({openModal : false});
  }

  getSteps() {
    return [
      'CWID',
      'Enrollment Eligibility',
      'Search & Add Class To Request',
      'Review Class(es) Added To Request',
      'Student Information',
      'Terms of Service',
      'Review and Submit'
    ];
  }

  getStepContent(stepIndex) {

    switch (stepIndex) {
      case 0:
        return <CWID />;
      case 1:
        return <Eligibilty />;
      case 2:
       // return <AddClasses />;
       return <SearchAndAddClasses />;
      case 3:
        return <ReviewClass/>;
      case 4:
        return <StudentInformation />;
      case 5:
        return <TermsofService />;
      case 6:
        return <Review />;
      default:
        return 'Unknown stepIndex';
    }

  }


  showErrorMessage = id => () => {
    this.setState({ errorMessage: this.geterrorMessage(id), showError: true });
  }


  geterrorMessage(id) {
    switch (id) {
      case 0:
        return '';
      case 1:
        return 'Sorry, we cannot process your request for approval and enrollment if you are an “admitted” student at CSUF and enrolled in classes in an admitted status at CSUF.  You may not enroll through Open University (as a guest student).  Please go to your Titan Online Registration appointment within your Student Home Page in the CSUF Portal.';
      case 2:
        return 'Sorry, we cannot process your request for approval and enrollment. Please go to <a href="https://extension.fullerton.edu/ou/faq.aspx" target="_blank">FAQ</a> and review the question regarding high school students and current policy and procedure.';
      case 3:
        return 'Sorry we cannot process your approval and enrollment request. Select financial and academic holds displaying in your Student Center (such as a Disqualification Advising Hold) will prevent enrollment from being processed, even if your request for approval has been granted by instructor and department.  Please log into the CSUF Portal and go to Titan Online to check your Holds.  You may contact the CSUF Student Help Desk at 657.278.8888 for Log-In assistance, if necessary.';
      case 4:
        return 'Sorry, we cannot process your approval and enrollment request. All applicants, regardless of citizenship, who have not attended, for at least three years, a school at the secondary level or higher, in which English was the principal language of instruction, are required to have the noted minimum scores of TOEFL or iBT TOEFL or IELTS or PTE.';
      case 5:
        return 'Sorry we cannot process your approval and enrollment request. It is important you get verification of this immunization or contact the CSUF Health Center at 657.278.2880 or at <a href="http://www.fullerton.edu/studentwellness/contact/" target="_blank">Contact</a> for further information.';
      case 6:
        return 'Student Visa Holders: Extension and International Programs is authorized to enroll F-1 and J-1 Visa holders who can establish they are maintaining their non-immigrant status with their sponsoring university or institution.  Extension and International Programs does not issue an I-20 form or DS-2019 form through Open University Enrollment, as students enrolled this way, are not considered to be full-time matriculated students for immigration purposes. Please be prepared to provide the additional information regarding your Immigrant Status, Refugee or asylum applicant status and other non-immigrant status at Student Information step page.';
      default:
        return 'Error.';
    }
  }



  showhide = param => () => {
    switch (param) {
      case 'hideerror':
        this.setState({ showError: false });
        break;
      case 'showques4':
        this.setState({ showques4: true });
        break;
      case 'showques6':
        this.setState({ showques6: true });
        break;
      case 'showques8':
        this.setState({ showques8: true });
        break;
      case 'showques11':
        this.setState({ showques11: true });
        break;
      case 'showImmigration':
        this.setState({ showImmigration: true });
        break;
      case 'hideques4':
        this.setState({ showques4: false });
        break;
      case 'hideques6':
        this.setState({ showques6: false });
        break;
      case 'hideques8':
        this.setState({ showques8: false });
        break;
      case 'hideques11':
        this.setState({ showques11: false });
        break;
      case 'hideImmigration':
        this.setState({ showImmigration: false });
        break;
      default:
        break;

    }
  }

  //Handle fields change
  handleChange = input => (e) => {
    //let errors = this.state.errors;
    switch (input) {
      case 'cwid':
        this.setState({ errorcwid: e.target.value.length > 9 ? 'CWID cannot exceed 9 digits.' : validCWIDRegex.test(e.target.value) ? '' : 'Please enter a valid 9 digit CWID.' });
        break;
      case 'resp1':
        this.setState({ errorresp1: '' });
        break;
      case 'resp2':
        this.setState({ errorresp2: '' });
        if (e.target.value === 0) {
          this.setState({ errorresp11: '', resp11: '' });
        }
        break;
      case 'resp3':
        this.setState({ errorresp3: '' });
        if (e.target.value === 1) {
          this.setState({ errorresp4: '', resp4: '' });
        }
        break;
      case 'resp4':
        this.setState({ errorresp4: '' });
        break;
      case 'resp5':
        this.setState({ errorresp5: '' });
        if (e.target.value === 1) {
          this.setState({ errorresp6: '', resp6: '' });
        }
        break;
      case 'resp6':
        this.setState({ errorresp6: '' });
        break;
      case 'resp7':
        this.setState({ errorresp7: '' });
        if (e.target.value === 1) {
          this.setState({ errorresp8: '', resp8: '' });
        }
        break;
      case 'resp8':
        this.setState({ errorresp8: '' });
        break;
      case 'resp9':
        this.setState({ errorresp9: '' });
        if (e.target.value === 1) {
          this.setState({
            errorimmigrationStatus: '', immigrationStatus: '', errorresidentcardNbr: '', residentcardNbr: '', errorI94admissionNbr: '',
            I94admissionNbr: '', errorvisatype: '', visatype: '', errorpassportNbr: '', passportNbr: '', erroradmissionNbr: '', admissionNbr: '', errorother: '', other: ''
          });
        }
        else {
          this.setState({ immigrationStatus: '' });
        }
        break;
      case 'resp10':
        this.setState({ errorresp10: '' });
        break;
      case 'resp11':
        this.setState({ errorresp11: '' });
        break;
      case 'immigrationStatus':
        this.setState({ errorimmigrationStatus: '' });
        if (e.target.value === 1) {
          this.setState({ errorI94admissionNbr: '', errorvisatype: '', errorpassportNbr: '', erroradmissionNbr: '', errorother: '' });
        }
        else if (e.target.value === 2) {
          this.setState({ errorresidentcardNbr: '', errorvisatype: '', errorpassportNbr: '', erroradmissionNbr: '', errorother: '' });
        }
        else if (e.target.value === 3) {
          this.setState({ errorresidentcardNbr: '', errorI94admissionNbr: '', errorother: '' });
        }
        else if(e.target.value === 4){
          this.setState({ errorI94admissionNbr: '', errorresidentcardNbr: '', errorvisatype: '', errorpassportNbr: '', erroradmissionNbr: '', errorother: '' });
        }
        else if (e.target.value === 5) {
          this.setState({ errorI94admissionNbr: '', errorresidentcardNbr: '', errorvisatype: '', errorpassportNbr: '', erroradmissionNbr: '' });
        }
        //this.setState({errorimmigrationStatus: e.target.value.length == 0 ? 'Immigration Status is required': ''});
        break;
      case 'other':
        this.setState({ errorother: e.target.value.length === 0 ? 'Other is required' : '' });
        break;
      case 'visatype':
        this.setState({ errorvisatype: e.target.value.length === 0 ? 'Visa Type is required' : '' });
        break;
      case 'passportNbr':
        this.setState({ errorpassportNbr: e.target.value.length === 0 ? 'Passport Number is required' : '' });
        break;
      case 'admissionNbr':
        this.setState({ erroradmissionNbr: e.target.value.length === 0 ? 'Admission Number is required' : '' });
        break;
      case 'I94admissionNbr':
        this.setState({ errorI94admissionNbr: e.target.value.length === 0 ? 'I94 Admission Number is required' : '' });
        break;
      case 'residentcardNbr':
        this.setState({ errorresidentcardNbr: e.target.value.length === 0 ? 'I-551 Alien Resident Card Number is required' : '' });
        break;
      case 'classnbr':
        this.setState({ errorclassnbr: validnumbersonlyRegex.test(e.target.value) ? '' : 'Please enter a valid Class Number.' });
        break;
      case 'firstname':
        this.setState({ errorfirstname: validlettersandspacesonlyRegex.test(e.target.value) ? '' : 'Please enter a valid First Name.' });
        break;
      case 'lastname':
        this.setState({ errorlastname: validlettersandspacesonlyRegex.test(e.target.value) ? '' : 'Please enter a valid Last Name.' });
        break;
      case 'email':
        this.setState({ erroremail: validEmailRegex.test(e.target.value) ? '' : 'Please enter a valid Email.' });
        break;
      case 'city':
        this.setState({ errorcity: validlettersandspacesonlyRegex.test(e.target.value) ? '' : 'Please enter a valid City.' });
        break;
      case 'state':
        this.setState({ errorstate: validlettersandspacesonlyRegex.test(e.target.value) ? '' : 'Please enter a valid State.' });
        break;
      case 'zip':
        this.setState({ errorzip: validnumbersonlyRegex.test(e.target.value) ? '' : 'Please enter a valid Zip.' });
        break;
      case 'reason':
        this.setState({ errorreason: '' });
        break;
      case 'reasonreentry':
        this.setState({ errorreasonreentry: '' });
        break;
      case 'homephone':
        this.setState({ errorhomephone: '' });
        break;
      case 'birthdate':
        this.setState({ errorbirthdate: '' });
        break;
      case 'gender':
        this.setState({ errorgender: '' });
        break;
      case 'addressline1':
        this.setState({ erroraddressline1: '' });
        break;
      case 'confirmemail':
        this.setState({ errorconfirmemail: e.target.value === this.state.email ? '' : 'Email Address you entered doesn\'t match.' });
        break;
      default:
        break;
    }

    this.setState({ [input]: e.target.value });
  }

  render() {

    const { classes } = this.props;
    const { activeStep, loading } = this.state;
    const steps = this.getSteps();

    
    return (
      <>
        <Header />
        <AppBar position="static">
                    <Toolbar variant="dense">
                        <Breadcrumbs aria-label="breadcrumb"  separator={<NavigateNext style={{ color: '#d1d1d1'}} fontSize="small" />}>         
                        <Link underline="hover" color="inherit" href="/app">
                            <Typography variant="caption" style={{ color: '#d1d1d1'}}>Home</Typography>  
                        </Link>
                        <Typography variant="caption" style={{ color: '#ffffff'}}>Create Request</Typography>
                        </Breadcrumbs>
                    </Toolbar>
        </AppBar>
        <Box className={classes.root} >
        <Grid container justify="space-between" spacing={1} style={{ display: 'flex', backgroundColor:'#DEE6F2',border:'1px solid #d1d2d5'}}>
                    <Grid item md={4} xs={6}>
                    <Box mx={2} mt={1}>
                        <Typography paragraph>
                        <Typography variant="subtitle2"><strong>{this.state.activeTermName}</strong></Typography>                       
                        <Typography variant="caption">Enrollment Approval Request</Typography>
                        </Typography>
                    </Box>
                    </Grid>
                    <Grid item md={3} xs={6}  style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button size="medium"
                    variant="contained"
                    color="inherit"
                    startIcon={<NavigateBefore />}
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    className={classes.button}
                    style={{ marginRight: 8 }}
                    >
                    Back
                    </Button>
                    <Button size="medium"
                    variant="contained"
                    color="primary"
                    endIcon={activeStep === steps.length - 1 ? <Publish /> : <NavigateNext />} 
                    onClick={this.handleNext}
                    className={classes.button}
                    >
                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                    
                    </Grid>
        </Grid>
      </Box>
      <Box className={classes.root} padding={1}>

        <Grid container spacing={2}>
          <Grid item xs={12} md={2} style={{border:'1px solid #dee0e3', backgroundColor: "#ffffff", marginTop: 3.5}}>
            <Stepper activeStep={activeStep} orientation="vertical" style={{backgroundColor: "#ffffff"}}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel><Typography variant="caption">{label}</Typography></StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12} md={10}>
            <Box padding={2}>
            <WrapperContext.Provider value={{
                ...this.state,
                handleChange: this.handleChange,
                showhide: this.showhide,
                Loading: this.showLoading,
                showErrorMessage: this.showErrorMessage,
                searchClass: this.searchClass,
                removeClass: this.removeClass,
                updateCart: this.updateCart,
                insertEnrollRequest: this.insertEnrollRequest,
                handleChangeCart: this.handleChangeCart,
                handleFileUpload: this.handleFileUpload,
                uploadDocuments: this.uploadDocuments,
                setSearchTerm: this.setSearchTerm,
                setSearchValue: this.setSearchValue,
                search: this.search,
                showRowDetails: this.showRowDetails,
                handlebackClassSearch: this.handlebackClassSearch,
                handlebackClassSearchResults: this.handlebackClassSearchResults,
                addClass: this.addClass,
                handleCommentChange: this.handleCommentChange,
                setHasSubmittedRequest: this.setHasSubmittedRequest
              }}>
                {activeStep === steps.length ? (
               <div className="app-container" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <div style={{flexGrow: 1}}>
                    <Confirmation />
                </div>
              </div> 
              ) : (<> 
              <div className="app-container" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <div style={{flexGrow: 1}}>
                {this.getStepContent(activeStep)}
                </div>
              </div>
              </>)}
              </WrapperContext.Provider>
            </Box>
          </Grid>
        </Grid>
        <Dialog open={this.state.openModal} onClose={this.onCloseModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle>
              Alert
              </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Alert severity="error"><div dangerouslySetInnerHTML={{ __html: this.state.errorModal}}></div></Alert>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" size="medium" onClick={this.onCloseModal}><Typography variant="overline" display="block">Close</Typography></Button>
            </DialogActions>
          </Dialog>
      </Box>
      <Footer />
      </>
    )
  }
}

export default withStyles(styles)(Wrapper);