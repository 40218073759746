

import React, { PureComponent } from 'react';
import { Grid, Typography,Divider, Box} from '@material-ui/core';
import { ArrowRightSharp } from '@material-ui/icons';


export default class TermsofService extends PureComponent{
    componentDidMount() {
        window.scrollTo(0, 0)
      } 
    render(){
        return(
            <>

        <Grid container spacing={2} direction="column" style={{ padding: 10 }}>
                <Grid item>
                    <Typography variant="subtitle1">
                    <b>Step 6 of 7: Terms of Service</b>
                    </Typography>
                </Grid>
        </Grid>
        <Grid container xs={12} md={11} lg={11} spacing={2} direction="column" style={{padding: 10}}>
              <Grid item>
                <Box mt={2} mb={2}>
                  <Typography display="block" variant="body2">
                    <ArrowRightSharp fontSize="inherit" /> Only 24 maximum units earned through Open University can apply towards the cumulative units of a bachelor’s degree earned at CSUF. Units through open university do not meet residency requirements for the undergraduate degree at CSUF.
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="body2">
                    <ArrowRightSharp fontSize="inherit" /> I have consulted with a graduate advisor at CSUF, if requesting to add a 500-level course. Usually only 9 units of graduate level courses completed through Open university count towards a degree at CSUF.
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="body2">
                    <ArrowRightSharp fontSize="inherit" /> I meet the stated prerequisite(s) or equivalent(s) for the course(s) listed below. I understand I may be required to provide documentation of prerequisites, if the instructor and/or Dept requests them. I understand I may be administratively dropped from the course(s) below and be subject to drop/withdrawal deadlines and refund policies for Open University, if unable to provide documentation of the prerequisites by the timeline required of the instructor or Dept.
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="body2">
                    <ArrowRightSharp fontSize="inherit" /> I have read and acknowledged the <a href="https://extension.fullerton.edu/ou/fall.aspx" target="_blank" rel="noopener noreferrer">drop/withdrawal deadlines</a> and <a href=" https://extension.fullerton.edu/ou/fees-resources.aspx" target="_blank" rel="noopener noreferrer">OU Fees Due/Owed and Enrollment Cancellation Policies</a> for Open University.
                  </Typography>
                </Box>
                <Divider style={{ margin: '20px 0' }} />
                <Box mb={2}>
                  <Typography variant="body2"><b>Disclaimer for Graduate Level Courses (500 level)</b></Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="body2">
                    <ArrowRightSharp fontSize="inherit" /> Please discuss your approval to enroll in a 500-level course with a graduate advisor or instructor before submitting an OU Request for Approval and Enrollment into a 500-level course.
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="body2">
                    <ArrowRightSharp fontSize="inherit" /> For 500-level courses in the College of Business and Economics, please contact Kellee Carter at <a href="mailto:kcarter@fullerton.edu" target="_blank" rel="noopener noreferrer">kcarter@fullerton.edu</a> with further questions and guidance.
                  </Typography>
                </Box>
              </Grid>
              <Box mt={3}>
              <Typography variant="body2">
                <b>By clicking Next, you agree to the </b>
                <a href="https://extension.fullerton.edu/professionaldevelopment/terms" target="_blank" rel="noopener noreferrer"><b>Terms of Service.</b></a>
              </Typography>
            </Box>
          </Grid>

          </>
        )
    }
}