

import React, { Component } from 'react';
import { Grid, Button, Container, Typography, TextField,Toolbar, AppBar,Box, Breadcrumbs, Link} from '@material-ui/core';
import Header from '../../../Layout/Header';
import Footer from '../../../Layout/Footer';
import {withRouter} from 'react-router-dom';
import { NavigateNext } from '@material-ui/icons';


class Track extends Component {

    constructor(props) {
        super(props)
        // Create the ref
        this.referenceid = React.createRef();

      }

      onSubmit = () => {
        this.props.history.push("/status/"+ this.referenceid.current.value.trim()) //doing redirect here.
      }


    render() {
        return (
        <>
            <Header/>
            <AppBar position="static">
                    <Toolbar variant="dense">
                        <Breadcrumbs aria-label="breadcrumb"  separator={<NavigateNext style={{ color: '#d1d1d1'}} fontSize="small" />}>         
                        <Link underline="hover" color="inherit" href="/app">
                            <Typography variant="caption" style={{ color: '#d1d1d1'}}>Home</Typography>  
                        </Link>
                        <Typography variant="caption" style={{ color: '#ffffff'}}>Track</Typography>
                        </Breadcrumbs>
                    </Toolbar>
            </AppBar>
            <Container style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', marginTop: 20 }}>
            <Box alignItems="center" justifyContent="center" style={{ flexGrow: 1 }}>
            <Grid container style={{marginTop: 40}} justify="center">
                <Grid item> 
                <Typography variant="h5"> Track</Typography><br/>
                </Grid>
                <Grid item style={{padding:5}}>
                    <Typography variant="caption">Please enter the tracking code below to <b>track</b> your request for enrollment {/*and <b>pay</b> for the approved class(es).*/}</Typography>
                </Grid>
                <Grid item container direction="row" style={{marginTop: 40}}  alignItems="center" justify="center">
                <Grid item xs={3}>
                <TextField  variant="outlined" inputRef={this.referenceid} size="small" color="primary" fullWidth label="Tracking Code"  InputLabelProps={{shrink: true}}/>
                </Grid>
                <Grid item>
                    <Button size='large' color="primary" variant="contained" style={{marginTop: 4}} onClick={this.onSubmit}>Search</Button>
                </Grid>
                </Grid>
                <Grid item>
                </Grid>
            </Grid>
            </Box>
        </Container>
        <Footer/>
        </>
        )
    }
}

export default withRouter(Track);