import React, { PureComponent } from 'react';
import { TextField, Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@material-ui/core';
import { WrapperContext } from './WrapperContext';
import { Delete, ArrowRightSharp, Publish} from '@material-ui/icons';
import { Alert} from '@material-ui/lab';
import WaitlistIcon from '../../../Layout/Waitlist.gif';
import ClosedIcon from '../../../Layout/Closed.gif';
import OpenIcon from '../../../Layout/Open.gif';



class AddClasses extends PureComponent{

    
    constructor(props) {
        super(props)
        this.state={
            open: false,
            openRow: null,
        }
        // Create the ref
        this.classNbr = React.createRef();
      }

      /*onSubmit = () => {
        const index = this.context.cart.findIndex(x => x.classnbr === this.classNbr.current.value);
        if (index > -1)
        {
            this.classNbr.current.value = null;
        }
        else if(this.context.errorclassnbr === '' && this.classNbr.current.value.length > 0)
        {
            this.context.searchClass({
                term: '2247',
                classNbr: this.classNbr.current.value
            });
            this.classNbr.current.value = null;
        }

      }*/

      handleClickOpen = () => {
        const index = this.context.cart.findIndex(x => x.classnbr === this.classNbr.current.value);
        if (index > -1)
        {
            this.classNbr.current.value = null;
        }
        else if(this.context.errorclassnbr === '' && this.classNbr.current.value.length > 0)
        {

            this.context.searchClass(
                {
                  term: '2253',
                  classNbr: this.classNbr.current.value
                },
                () => {
                  if (this.context.errorclassnbr === '') {
                    this.setState({ open: true });
                  }
                  this.classNbr.current.value = null;
                }
              );
        }
      }

      handleClose = () => {
        this.setState({ open: false });
      }

      handleConfirm = () => {
        // Call the updateCart function passed via props
        this.context.updateCart(this.context.class);
        this.handleClose();
      };

    static contextType = WrapperContext; 
 
    
    render(){

        return(
            <>
        <Grid container spacing={2} style={{padding: 10}}>
            <Grid item>
            <Typography variant="overline"><strong>Request Classes</strong></Typography>  <br/>
            </Grid>

            <Grid container spacing={2} direction="row" style={{padding: 10}}>
            <Grid item xs={6}>
                <Typography variant="caption">
                    To search for a class, please refer to <a href="http://www.fullerton.edu/schedule" target="_blank" rel="noopener noreferrer"><u>www.fullerton.edu/schedule</u></a><br/>
                    <ArrowRightSharp fontSize="inherit"/>Choose Term: Fall/Spring<br/>
                    <ArrowRightSharp fontSize="inherit"/>Choose Course Career: Undergraduate and Postbaccalaureate
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="caption">
                    <ArrowRightSharp fontSize="inherit"/>Example: 01-LEC (10520) HIST 110A (In this example, 10520 is the class number.)<br/>
                    <ArrowRightSharp fontSize="inherit"/>For a combined section course type, please add the class number of the lecture portion only, as the other components of activity and/or lab will be automatically included in the request.
                </Typography>
            </Grid>
            </Grid>
            
            <TableContainer component={Paper} style={{marginTop: 30}}>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell style={{width: '25%'}}>Description</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Upload Transcripts*</TableCell>
                    <TableCell style={{width: '21%'}}>Comments</TableCell>
                    <TableCell>Actions</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {this.context.cart.map(x => (
                    <TableRow key={x.classnbr}>
                    <TableCell  style={{width: '30%'}}><Typography variant="subtitle1"><b>{x.descr}</b></Typography><br/><Typography variant="caption" paragraph>Class Number: {x.classnbr} <br/>Course: {x.subject}-{x.catalognbr}-{x.section} <br/>Days & Times: {x.meeting}<br/>Meeting Dates: {x.startdate}-{x.enddate}<br/>Units: {x.unitsmax}<br/>Instructor: {x.instructorname}</Typography></TableCell>
                    <TableCell>
                    {(x.enrlstatus === "C" && (x.waitlistmax === x.waitlisttotal)) ? <><img src={ClosedIcon} alt="close"/><span><Typography variant="caption"> Closed</Typography></span></>: ((x.enrlstatus === "C" && (x.waitlisttotal < x.waitlistmax)) ? <><img src={WaitlistIcon} alt="waitlist"/><span><Typography variant="caption"> Wait List</Typography></span></> : (x.enrlstatus === "O" ? <><img src={OpenIcon} alt="open"/><span><Typography variant="caption"> Open</Typography></span></>: ''))}
                    </TableCell>
                    <TableCell>
                        <Button startIcon={<Publish/>} variant="outlined" size="small" component="label">
                        Upload
                            <input type="file" onChange={(e) => this.context.handleFileUpload(e, x.classnbr)}/>
                        </Button>
                    </TableCell>
                    <TableCell style={{width: '21%'}}>
                        <Typography variant="caption">
                            Add your comments for instructor and academic department about why you are requesting to add this course.
                        </Typography>
                        <TextField
                        fullWidth
                        multiline
                        rows={2}
                        variant="outlined"
                        placeholder="Comments"
                        />
                    </TableCell>
                    <TableCell> <Button variant="outlined" size="small" onClick={this.context.removeClass(x.classnbr)} startIcon={<Delete />}>Remove</Button></TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell colSpan={7}>
                    <Grid container justify="space-between" spacing={1} style={{ display: 'flex' }}>
                    <Grid item xs={6}>
                        <Grid item xs={5} alignItems="center" style={{display: 'flex'}}>
                            <Typography variant="caption" paragraph><strong>{this.context.cart.length} Class(es)</strong> added to your approval request to enroll.<br/><br/> 
                            <strong>You can add {5 - this.context.cart.length} Class(es) to this approval request.</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid item container spacing={1} alignItems="center" justifyContent="flex-end" style={{marginTop: 3}}>
                        <Grid item xs={6}>
                        <TextField autoComplete="off" size="small" fullWidth name="classnbr" variant="outlined" inputRef={this.classNbr} style={{backgroundColor: "#ffffff"}} color="primary" onChange={this.context.handleChange('classnbr')} label="Class Number"  InputLabelProps={{shrink: true}}/>
                        </Grid>
                        <Grid item xs={6}>
                        <Button id="AddClass" size="medium" variant="contained" color="primary" onClick={this.handleClickOpen} disabled={this.context.cart.length === 5? true : false}>Add Class</Button>
                        {/*onClick={this.onSubmit}*/}
                        </Grid>                      
                        </Grid>
                    </Grid>
                    </Grid>
                    {this.context.errorclassnbr.length > 0 && (<><Grid item xs={12}><Typography variant="caption" style={{color: "#ff0000"}}>{this.context.errorclassnbr}</Typography></Grid></>)}
                    {this.context.cart.length === 5 && (
                    <Grid item>
                        <Alert  variant="outlined" severity="error">
                            Cart has reached its maximum limit of 5 classes.
                        </Alert>
                    </Grid>)}
                    </TableCell>
                </TableRow>
                </TableBody>
            </Table>
            </TableContainer>
            <Grid container style={{marginTop: 10}}>
                <Grid item>
                <Typography variant="caption"><b>*Upload Transcripts:</b> If necessary or requested, you may upload copies of unofficial transcripts here demonstrating course prerequisites especially if completed outside of CSUF.</Typography>
                </Grid>
            </Grid>
            

        {this.context.cart.length > 0?(<>
            <Grid item container style={{padding: 5, backgroundColor:'#f8f8f8', marginTop: 10}}>
                <Grid item xs={12}>
                <Typography variant="caption">For more information on OU fees and expected totals, go to the listed hyperlink.<br/>USA Students: Refer to your program information for fees paid.<br/> <a href="https://extension.fullerton.edu/ou/fees-resources.aspx" target="_blank" rel="noopener noreferrer">Open University Fee-Resources</a></Typography><br/> 
                </Grid>
                {/*<Grid item container justify="space-between" xs={6}>
                    <Grid item xs={6}>
                    <Typography variant="caption">Sub Total</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="caption" style={{float: 'right'}}> ${this.context.subtotal}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="caption">Consolidation Fee</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="caption" style={{float: 'right'}}> ${this.context.consolidationfee}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="caption">Titan Card Fee</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="caption"  style={{float: 'right'}}> ${this.context.titancardfee}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Divider/>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="caption">Estimated Total</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="caption"  style={{float: 'right'}}> ${this.context.total.toFixed(2)}</Typography>
                    </Grid>
                </Grid>*/}
                <br/><br/>
            </Grid>
        </>
        ): ""}
        </Grid>
        {/* Modal Dialog */}
        <Dialog open={this.state.open} onClose={this.handleClose}>
            <DialogTitle><Typography variant="overline">Confirm Class Request</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    <Typography variant="subtitle1">
                     Are you sure you want to request this class?
                    </Typography>
                    </DialogContentText>
                    {this.context.class.map(x => (
                        <div id={x.classnbr} style={{marginTop: 10}}>
                        <Box display="flex" alignItems="center">
                        
                        <Typography variant="subtitle2">
                            <b>{x.descr}</b>
                        </Typography>
                        </Box><br/>
                        <Grid>
                        <Grid item>
                        <Typography variant="caption">
                            <b>Class:</b> {x.classnbr}<br/>
                            <b>Course:</b> {x.subject}-{x.catalognbr}-{x.section}<br/>
                            <b>Days and Times:</b> {x.meeting} <br/>
                            <b>Meeting Dates:</b> {x.startdate}-{x.enddate}<br/>
                            <b>Units:</b> {x.unitsmax}<br/>
                            <b>Instructor:</b> {x.instructorname}<br/>
                        </Typography>
                        </Grid>
                        <Grid item>
                        <Box display="flex" alignItems="center">
                        <Typography variant="caption"><b>Status:</b> </Typography>            
                        {(x.enrlstatus === "C" && (x.waitlistmax === x.waitlisttotal)) ? <><img src={ClosedIcon} alt="close"/><span><Typography variant="caption"> Closed</Typography></span></>: ((x.enrlstatus === "C" && (x.waitlisttotal < x.waitlistmax)) ? <><img src={WaitlistIcon} alt="waitlist"/><span><Typography variant="caption"> Wait List</Typography></span></> : (x.enrlstatus === "O" ? <><img src={OpenIcon} alt="open"/><span><Typography variant="caption"> Open</Typography></span></>: ''))} 
                        </Box> 
                        </Grid>
                        </Grid>
                        <br/>
                        </div>

                    ))}
                </DialogContent>
                <DialogActions>
                        <Button onClick={this.handleClose} color="primary" variant="contained">
                            Cancel
                        </Button>
                        <Button onClick={this.handleConfirm} color="primary" variant="contained">
                            Confirm
                        </Button>
                </DialogActions>
        </Dialog>
         </>
        );
    }
}

export default (AddClasses);