import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Components/OpenUniversity/Enrollment/Home';
import verticalStepper from './Components/OpenUniversity/Enrollment/Stepper';
//import HomeUSA from './Components/OpenUniversity/Enrollment/HomeUSA';
import Wrapper from './Components/OpenUniversity/Enrollment/Wrapper';
//import WrapperUSA from './Components/OpenUniversity/Enrollment/WrapperUSA';
//import WrapperOUSUMWIN from './Components/OpenUniversity/Enrollment/WrapperOUSUMWIN';
import Track from './Components/OpenUniversity/Enrollment/Track';
//import TrackUSA from './Components/OpenUniversity/Enrollment/TrackUSA';
import Approval from './Components/OpenUniversity/Enrollment/Approval';
import Status from './Components/OpenUniversity/Enrollment/Status';
//import Status_v2 from './Components/OpenUniversity/Enrollment/Status_v2';
//import StatusUSA from './Components/OpenUniversity/Enrollment/StatusUSA';
import ApprovalDashboard from './Components/OpenUniversity/Enrollment/ApprovalDashboard';
import { isIE } from 'react-device-detect';
//import Maintenance from './Components/OpenUniversity/Enrollment/Maintenance';
//import WebpageUnavailable from './Components/OpenUniversity/Enrollment/WebpageUnavailable';
import Login from './Components/OpenUniversity/Admin/Login';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import FileUpload from './Components/OpenUniversity/Enrollment/FileUpload';


const theme = createMuiTheme({
  palette: {
     primary: {
        main: "#3f51b5" // This is an orange looking color #006fcf
               },
     secondary: {
        main: "#ffcc80" //Another orange-ish color
                }
           },

});
function App() {
  const now = new Date();
  const enableDateTime = new Date("2025-01-06T07:59:00");
  const enableRoutes = now > enableDateTime;

  if (isIE)
  return (
    <div>
      <h1>Hi there. You’re using an outdated browser</h1>
      <p>For a safer and faster user experience use a modern browser like Chrome, Firefox, Safari, Opera, or Edge.</p>
    </div>
  )
  return (
    <>
    <ThemeProvider theme={theme}>
    <BrowserRouter basename="/app">
        <Switch>
          <Route path="/" component={Home} exact></Route>
          <Route path="/Create" component={Wrapper}></Route>
          <Route path="/Approval" component={Approval}/>
          <Route path="/Track" component={Track}/>
          <Route path="/Status/:id" component={Status}/>  
          <Route path='/ApprovalDashboard' component={ApprovalDashboard} exact></Route>
          <Route path='/ApprovalDashboard/:id' component={ApprovalDashboard}></Route>
          <Route path="/Admin/Login" component={Login}/>
          <Route path="/stepper" component={verticalStepper}/>
          <Route path="/FileUpload" component={FileUpload}></Route>  
        </Switch>
    </BrowserRouter>
    </ThemeProvider>
    </>
  );
}

export default App;
