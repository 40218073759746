import React, { Component } from 'react';
import { TextField, Button, Paper, Grid, Typography, RadioGroup, Radio, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { WrapperContext } from './WrapperContext';
import { withStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

const styles = theme => ({
  margin: {
    margin: theme.spacing(2),
  },
});


class Eligibility extends Component {
  static contextType = WrapperContext;
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const { classes } = this.props;
    const Ques4 = () => (
      <Grid item >
        <Paper variant="outlined">
          <div className={classes.margin}>
            <Typography variant="body2">{this.context.ques4}</Typography>
            <RadioGroup row aria-label="ques4" name="ques4" onChange={this.context.handleChange('resp4')} defaultValue={this.context.resp4}>
              <FormControlLabel value="1" control={<Radio color="primary" size="small" />} label="Yes" />
              <FormControlLabel value="0" control={<Radio color="primary" size="small" />} label="No" onClick={this.context.showErrorMessage(4)} />
            </RadioGroup>
            {this.context.errorresp4.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorresp4}</Typography>}
          </div>
        </Paper>
      </Grid>
    )

    const Ques6 = () => (
      <Grid item >
        <Paper variant="outlined">
          <div className={classes.margin}>
            <Typography variant="body2">{this.context.ques6}</Typography>
            <RadioGroup row aria-label="ques6" name="ques6" onChange={this.context.handleChange('resp6')} defaultValue={this.context.resp6}>
              <FormControlLabel value="1" control={<Radio color="primary" size="small" />} label="Yes" />
              <FormControlLabel value="0" control={<Radio color="primary" size="small" />} label="No" onClick={this.context.showErrorMessage(5)} />
            </RadioGroup>
            {this.context.errorresp6.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorresp6}</Typography>}
          </div>
        </Paper>
      </Grid>
    )

    const Ques8 = () => (
      <Grid item >
        <Paper variant="outlined">
          <div className={classes.margin}>
            <Typography variant="body2">{this.context.ques8}</Typography>
            <RadioGroup row aria-label="ques8" name="ques8" onChange={this.context.handleChange('resp8')} defaultValue={this.context.resp8}>
              <FormControlLabel value="1" control={<Radio color="primary" size="small" />} label="Yes" />
              <FormControlLabel value="0" control={<Radio color="primary" size="small" />} label="No" onClick={this.context.showErrorMessage(5)} />
            </RadioGroup>
            {this.context.errorresp8.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorresp8}</Typography>}
          </div>
        </Paper>
      </Grid>
    )

    const Ques11 = () => (
      <Grid item >
        <Paper variant="outlined">
          <div className={classes.margin}>
            <Typography variant="body2">{this.context.ques11}</Typography>
            <RadioGroup row aria-label="ques11" name="ques11" onChange={this.context.handleChange('resp11')} defaultValue={this.context.resp11}>
              <FormControlLabel value="1" control={<Radio color="primary" size="small" />} label="Yes" />
              <FormControlLabel value="0" control={<Radio color="primary" size="small" />} label="No" onClick={this.context.showErrorMessage(2)} />
            </RadioGroup>
            {this.context.errorresp11.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorresp11}</Typography>}
          </div>
        </Paper>
      </Grid>
    )
    


    return (
      <>
          <Grid container spacing={2} direction="column" style={{ padding: 10 }}>
             <Typography variant="subtitle1">
                    <b>Step 2 of 7: Enrollment Eligibilty</b>
            </Typography>
            <br/>
            <Grid item >
              <Typography variant="body2">{this.context.ques1}</Typography>
            </Grid>
            <Grid item>
              <RadioGroup row aria-label="ques1" name="ques1" onChange={this.context.handleChange('resp1')} defaultValue={this.context.resp1} >
                <FormControlLabel value='1' control={<Radio color="primary" size="small" />} label="Yes" size="small" onClick={this.context.showErrorMessage(1)} />
                <FormControlLabel value='0' control={<Radio color="primary" size="small" />} label="No" />
              </RadioGroup>
              {this.context.errorresp1.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorresp1}</Typography>}
            </Grid>
            <Grid item>
              <Typography variant="body2">{this.context.ques2}</Typography>
              <RadioGroup row aria-label="ques2" name="ques2" onChange={this.context.handleChange('resp2')} defaultValue={this.context.resp2}>
                <FormControlLabel value='1' control={<Radio color="primary" size="small" />} label="Yes" onClick={this.context.showhide('showques11')} />
                <FormControlLabel value='0' control={<Radio color="primary" size="small" />} label="No" onClick={this.context.showhide('hideques11')}/>
              </RadioGroup>
              {this.context.errorresp2.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorresp2}</Typography>}
            </Grid>
            {this.context.showques11 && <Ques11 />}
            <Grid item >
              <Typography variant="body2">{this.context.ques3}</Typography>
              <RadioGroup row aria-label="ques3" name="ques3" onChange={this.context.handleChange('resp3')} defaultValue={this.context.resp3}>
                <FormControlLabel value="1" control={<Radio color="primary" size="small" />} label="Yes" onClick={this.context.showhide('hideques4')} />
                <FormControlLabel value="0" control={<Radio color="primary" size="small" />} label="No" onClick={this.context.showhide('showques4')} />
              </RadioGroup>
              {this.context.errorresp3.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorresp3}</Typography>}
            </Grid>
          
            {this.context.showques4 && <Ques4 />}
          {/*
            <Grid item >
              <Typography variant="body2">{this.context.ques5}</Typography>
              <RadioGroup row aria-label="ques5" name="ques5" onChange={this.context.handleChange('resp5')} defaultValue={this.context.resp5}>
                <FormControlLabel value="1" control={<Radio color="primary" size="small" />} label="Yes" onClick={this.context.showhide('hideques6')} />
                <FormControlLabel value="0" control={<Radio color="primary" size="small" />} label="No" onClick={this.context.showhide('showques6')} />
              </RadioGroup>
              {this.context.errorresp5.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorresp5}</Typography>}
            </Grid>

            {this.context.showques6 && <Ques6 />}

            <Grid item >
              <Typography variant="body2">{this.context.ques7}</Typography>
              <RadioGroup row aria-label="ques7" name="ques7" onChange={this.context.handleChange('resp7')} defaultValue={this.context.resp7}>
                <FormControlLabel value="1" control={<Radio color="primary" size="small" />} label="Yes" onClick={this.context.showhide('hideques8')} />
                <FormControlLabel value="0" control={<Radio color="primary" size="small" />} label="No" onClick={this.context.showhide('showques8')} />
              </RadioGroup>
              {this.context.errorresp7.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorresp7}</Typography>}
            </Grid>

            {this.context.showques8 && <Ques8 />}
            */}
            <Grid item >
              <Typography variant="body2">{this.context.ques9}</Typography>
              <RadioGroup row aria-label="ques9" name="ques9" onChange={this.context.handleChange('resp9')} defaultValue={this.context.resp9}>
                <FormControlLabel value="1" control={<Radio color="primary" size="small" />} label="Yes" onClick={this.context.showhide('hideImmigration')} />
                <FormControlLabel value="0" control={<Radio color="primary" size="small" />} label="No" onClick={this.context.showhide('showImmigration')} />
              </RadioGroup>
              {this.context.errorresp9.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorresp9}</Typography>}
            </Grid>
            <div style={{ display: this.context.showImmigration === true? "block": "none" }}>
              <Paper variant="outlined">
              <Grid item>
                  <div className={classes.margin}>
                    <Typography variant="body2">Select your Immigration Status:</Typography>
                    <RadioGroup aria-label="immigrationStatus" name="immigrationStatus" onChange={this.context.handleChange('immigrationStatus')} defaultValue={this.context.immigrationStatus}>
                      <FormControlLabel value="1" control={<Radio color="primary" size="small" />} label={<Typography variant="body2">Permanent Resident</Typography>} />
                      <FormControlLabel value="2" control={<Radio color="primary" size="small" />} label={<Typography variant="body2">Refugee or Asylum</Typography>} />
                      <FormControlLabel value="3" control={<Radio color="primary" size="small" />} label={<Typography variant="body2">Non-Immigrant in the US on a visa.</Typography>} />
                      <FormControlLabel value="4" control={<Radio color="primary" size="small" />} label={<Typography variant="body2">Enrolled through the University Semester Abroad (USA) Program at CSUF</Typography>} />
                      <FormControlLabel value="5" control={<Radio color="primary" size="small" />} label={<Typography variant="body2">Other</Typography>}/>
                    </RadioGroup>
                    {this.context.errorimmigrationStatus.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorimmigrationStatus}</Typography>}
                  </div>
                </Grid>
              <Grid item>
              </Grid>
              <div style={{ display: this.context.immigrationStatus === 5 ? "block" : "none", padding: 10 }}>
                  <Grid item xs={6}>
                    <TextField autoComplete="off" fullWidth size="small" variant="outlined" multiline rows={2} label="Other" margin="dense" name="other" onChange={this.context.handleChange('other')} value={this.context.other} color="primary" InputLabelProps={{ shrink: true }} />
                    {this.context.errorother.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorother}</Typography>}
                  </Grid>
              </div>
              <div style={{ display: this.context.immigrationStatus === 3 ? "block" : "none", padding: 10 }}>
                  <Grid item container spacing={2}>
                  <Grid item xs={4}>
                  <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="Visa Type" margin="dense" name="visatype" onChange={this.context.handleChange('visatype')} value={this.context.visatype} color="primary" InputLabelProps={{ shrink: true }} />
                  {this.context.errorvisatype.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorvisatype}</Typography>}
                  </Grid>
                  <Grid item xs={4}>
                  <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="Passport Number" margin="dense" name="passportNbr" onChange={this.context.handleChange('passportNbr')} value={this.context.passportNbr} color="primary" InputLabelProps={{ shrink: true }} />
                  {this.context.errorpassportNbr.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorpassportNbr}</Typography>}
                  </Grid>
                  <Grid item xs={4}>
                  <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="Admission Number" margin="dense" name="admissionNbr" onChange={this.context.handleChange('admissionNbr')} value={this.context.admissionNbr} color="primary" InputLabelProps={{ shrink: true }} />
                  {this.context.erroradmissionNbr.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.erroradmissionNbr}</Typography>}
                  </Grid>
                  <Grid item>
                  <Alert severity="info">
                  <AlertTitle>Note</AlertTitle>
                  <Typography variant="body2">Students here in the United States on an F-1 or J-1 Visa and passport will be required to provide an official signed letter from the foreign student advisor at your current sponsoring university/college that documents your permission to enroll at CSUF through Open University.</Typography>
                  </Alert>
                  </Grid>
                  </Grid>
              </div>
              <div style={{ display: this.context.immigrationStatus === 2 ? "block" : "none", padding: 10 }}>
                  <Grid item xs={6}>
                  <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="I-94 Admission Number" margin="dense" name="I94admissionNbr" onChange={this.context.handleChange('I94admissionNbr')} value={this.context.I94admissionNbr} color="primary" InputLabelProps={{ shrink: true }} />
                  {this.context.errorI94admissionNbr.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorI94admissionNbr}</Typography>}
                  </Grid>
              </div>
              <div style={{ display: this.context.immigrationStatus === 1 ? "block" : "none", padding: 10 }}>
                  <Grid item xs={6}>
                  <TextField autoComplete="off" fullWidth size="small" variant="outlined" label="I-551 Alien Resident Card Number" margin="dense" name="residentcardNbr" onChange={this.context.handleChange('residentcardNbr')} value={this.context.residentcardNbr} color="primary" InputLabelProps={{ shrink: true }} />
                  {this.context.errorresidentcardNbr.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorresidentcardNbr}</Typography>}
                  </Grid>
              </div>
              <Grid item>
              </Grid>
              </Paper>
            </div>
            <Grid item xs={8} >
              <Typography variant="body2">{this.context.ques10}</Typography><br/>
              <Typography variant="body2">An approved enrollment will not be processed if you have active academic or registration holds that need to be waived or removed. Address these first.<br/><br/> 
                *If you are new to CSUF, you should not have any holds and can answer, Yes. 
              </Typography>
              {/*<Typography variant="body2"><b>Be aware that select Holds will block enrollment. Please address them first. Please clear any registration holds prior to submitting your enrollment request. </b></Typography><br/>
              <Typography variant="body2">Students with no prior CSUF record, should answer Yes.</Typography>*/}
              <RadioGroup row aria-label="ques10" name="ques10" onChange={this.context.handleChange('resp10')} defaultValue={this.context.resp10}>
                <FormControlLabel value="1" control={<Radio color="primary" size="small" />} label="Yes" />
                <FormControlLabel value="0" control={<Radio color="primary" size="small" />} label="No" onClick={this.context.showErrorMessage(3)} />
              </RadioGroup>
              {this.context.errorresp10.length > 0 && <Typography variant="body2" style={{color: "#ff0000"}}>{this.context.errorresp10}</Typography>}
            </Grid>
            <Grid item />


            <Dialog open={this.context.showError} onClose={this.context.showhide('hideerror')} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle>
              Alert
              </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Alert severity="error"><div dangerouslySetInnerHTML={{ __html: this.context.errorMessage}}></div></Alert>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" size="medium" onClick={this.context.showhide('hideerror')}><Typography variant="overline" display="block">Close</Typography></Button>
            </DialogActions>
          </Dialog>

          </Grid>


      </>
    );
  }
}

export default withStyles(styles)(Eligibility);