import React, { PureComponent } from 'react';
import { Grid, Button, Typography, Paper, TextField} from '@material-ui/core';
import Header from '../../../Layout/Header';
import Auth from '../Admin/Auth'

export default class Login extends PureComponent {

    onSubmit = () => {
        this.props.history.push("/status/EAAF03D") //doing redirect here.
      }

    render() {
        
        return (
        <>
        <Header/>
        <div>
            <Grid container spacing={0} justify="center" direction="row" style={{marginTop: 80}}>
                <Grid item>
                <Grid container direction="column" justify="center" spacing={2} style={{ justifycontent: 'center',minheight: '90vh'}}>
                <Paper variant="elevation"  elevation={2} style={{justifycontent:'center',minheight: '30vh',padding: '50px'}}>
                <Grid item>
                    <Typography variant="body1">Open University Admin Portal</Typography><br/>
                </Grid>
                <Grid item>
                <form onSubmit={this.handleSubmit}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <TextField type="email" placeholder="Email" fullWidth name="username" variant="outlined" size="small" required autoFocus/>
                        </Grid>
                        <Grid item>
                            <TextField type="password" placeholder="Password" fullWidth name="password" variant="outlined" size="small" required/>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" type="submit" fullWidth onClick={this.onSubmit}>Sign in</Button>
                        </Grid>
                    </Grid>
                </form>
                </Grid>
                </Paper>
                </Grid>
                </Grid>
            </Grid>
        </div>
        </>
        )
    }
}
