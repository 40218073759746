import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Header from '../../../Layout/Header';
import Footer from '../../../Layout/Footer';
import { Grid, Box } from '@material-ui/core';
import CWID from './CWID';
import Eligibilty from './Eligibility';
import AddClasses from './AddClasses';
import TermsofService from './TermsofService';
import Review from './Review';
import StudentInformation from './StudentInformation';
import Confirmation from './Confirmation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return [ 'CWID',
    'Enrollment Eligibility',
    'Request Classes',
    'Student Information',
    'Terms of Service',
    'Review and Submit'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <CWID/>;
    case 1:
      return 'An ad group contains one or more ads which target a shared set of keywords.';
    case 2:
      return `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`;
    default:
      return 'Unknown step';
  }
}

export default function VerticalLinearStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <React.Fragment>
      <Header />
     <Box className={classes.root} >
        <Grid container justify="space-between" spacing={1} style={{ display: 'flex',backgroundColor: "#ebeef2",border:'1px solid #dedfe1'}}>
                    <Grid item md={4} xs={7} style={{paddingLeft: 10 }}>
                        <Typography variant="caption" paragraph><strong>Fall 2024</strong><br/>Enrollment Approval Request</Typography>
                    </Grid>
                    <Grid item md={2} xs={4}>
                    <Button size="medium"
                    variant="contained"
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                    >
                    Back
                    </Button>
                    <Button size="medium"
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                    </Grid>
        </Grid>
      </Box>
      <Box className={classes.root} padding={1}>

        <Grid container spacing={2}>
          <Grid item xs={12} md={2} style={{border:'1px solid #dedfe1', backgroundColor: "#ebeef2", marginTop: 3}}>
            <Stepper activeStep={activeStep} orientation="vertical" style={{backgroundColor: "#ebeef2"}}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel><Typography variant="caption">{label}</Typography></StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12} md={10}>
            <Box padding={2}>
              <Typography>{getStepContent(activeStep)}</Typography>
              <div className={classes.actionsContainer}>
                <div>

                </div>
              </div>
              {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                  <Typography>All steps completed - you&apos;re finished</Typography>
                  <Button onClick={handleReset} className={classes.button}>
                    Reset
                  </Button>
                </Paper>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </React.Fragment>
  );
}