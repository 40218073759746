import React, { Component } from 'react';
import { Grid,Divider, Container, Avatar, Typography, AppBar, Toolbar, Breadcrumbs, LinearProgress,  Table, TableCell, TableBody, TableHead, TableRow, TableContainer, Paper, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import Header from '../../../Layout/Header';
import Footer from '../../../Layout/Footer';
import Emblem from '../../../Layout/CSUF-emblem.png';

import { KeyboardArrowRight, FileCopy, AccountCircle } from '@material-ui/icons';



const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
});

class ApprovalDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            accountid: '',
            approvalrequests: [],
            approvername: '',
            typeid: 0,
            value: 2,
            shibbfirstname: '',
            shibblastname: '',
            shibbemail: ''
        };
    }

    onCloseModal = ()=>{
        this.setState({openModal : false});
    }
    onOpenModal = ()=>{
        this.setState({openModal : true});
    }

    componentDidMount() {
        this.setState({ loading: true});
        this.callBackendAPI()
        .then(res => {
         
          const { location, match } = this.props;

          // Extract query parameter or route parameter
          const queryParams = new URLSearchParams(location.search);
          const id = queryParams.get('accountid') || match.params.id;

          const returnUrl = 'approvaldashboard';

          if (res.express === 'Shibboleth Session not established') {
            const redirectURL = `https://extension.fullerton.edu/app/express/auth?accountid=${id}&return=${returnUrl}`;
            window.location.href = redirectURL;
            return;
          } 

          this.setState(
            {
              shibbfirstname: res.firstname,
              shibblastname: res.lastname,
              shibbemail: res.email,
              accountid: id
            },
            () => {

            //const {location} =this.props;
            //const queryParams = new URLSearchParams(location.search);
            //const id = queryParams.get('accountid');

            let requestBody = {
                //accountid: this.state.accountid
                //accountid: this.props.match.params.id
                accountid: id
            };

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                body: JSON.stringify(requestBody)
            };

            fetch('https://extension.fullerton.edu/data/ou/approvalaccount', requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        // this.setState(this.baseState);
                        this.setState({ 
                            trackingCode: data, 
                            loading: false, 
                            approvalrequests: data.approvals,
                            approvername: data.approver.name,
                            typeid: data.approver.typeid  
                        });
                        //alert(JSON.stringify(this.state.approvername));
                    }
                })
                .catch(data => { alert(data); });
            }
          );
        })
        .catch(err => console.log(err));
        
    }

    callBackendAPI = async () => {
        const response = await fetch('/app/express/attributes');
        const body = await response.json();
    
        if (response.status !== 200) {
          throw Error(body.message) 
        }
        return body;
      };

    render() {
        const { loading } = this.state;

        return (
            <>
                <Header />
                <AppBar position="static">
                    <Toolbar variant="dense">
                        <Breadcrumbs aria-label="breadcrumb" >
                            <Typography style={{ color: '#d1d1d1' }} variant="caption"> Approval Requests</Typography>
                        </Breadcrumbs>
                    </Toolbar>
                </AppBar>
                <Container component="main">
                    {loading && <LinearProgress style={{ height: 6 }} />}
                    <Paper style={{padding: 50,marginTop: 20}} elevation={1}>
                    <Grid container direction="column">
                    <Grid item container spacing={1} alignItems="center">
                        <Grid item>
                        <img src={Emblem} alt="Logo" style={{width: 30, height: 57, display: 'block',maxWidth: '100%', maxHeight: '100%'}}/>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5">Open University</Typography>
                            <Typography variant="overline">Extension and International Programs</Typography>  
                        </Grid>
                        <Grid item xs={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Typography variant="overline">Course Approval Request Dashboard</Typography>
                    </Grid>
                    </Grid>
                    </Grid>
                    <Grid item style={{marginTop: 15}}>
                        <Divider/>
                    </Grid>
                    <Grid item style={{marginTop: 30}}>
                    <Grid item container spacing={1} alignItems="center">
                        <Grid item>
                        <AccountCircle/>
                        </Grid>
                        <Grid item>
                        <Typography variant="subtitle2"><b> Welcome, {this.state.approvername} {this.state.typeid === 2 ?'Department' : ''}</b></Typography>
                        </Grid>
                    </Grid>
                    <br/>         
                    {/* <Typography variant="caption">
                    <p><b>{this.state.shibbfirstname} {this.state.shibblastname} {this.state.shibbemail}</b><br/>
                    </p>
                    </Typography> <br/> */}
                    {this.state.typeid === 1 ?
                        <Typography variant="caption"> 
                        <p>The information below are requests from the students to enroll into a course section you are teaching this semester. These are non-admitted students who have identified a specific academic purpose for this course and acknowledges prerequisites or the equivalent have been met.</p>
                        <p>Students are required to request approval to enroll in a course section, from the instructor and department of the course, whether the section is open, closed or wait-listed. Once approved by you, this information is routed to the department-assigned email address for similar approval.</p>
                        <p>If the course has an active wait-list (which are only for admitted students), you may grant approval for the students to add, but the request for approval to add, will not route further to the Dept for review and approval, until Monday of week two of the semester, which is after wait-lists have expired. This is the first date the Dept may consider approval and place a permit, if space and other factors allow.</p>
                        <p>Please be responsive to providing access to the course in Canvas by the start of the course, for enrollments that may be delayed or are occurring after the start of the semester. Access to view one’s course in the LMS platform is not official enrollment. Always refer to your official class roster for this information.</p>
                        </Typography>
                        :
                        <Typography variant="caption">
                        <p>The information below are pending requests for approval from students asking to enroll into course sections associated with your Department.
                        <br/>Students are required to request approval to enroll in all course sections during the Fall and Spring semesters (whether the sections display as open, closed or wait-listed).</p>
                        <p>The request is first reviewed by the instructor, and if approved by the instructor, then routes to the Dept for review.</p>
                        <p>Please be responsive to the pending requests for approval for your Dept at this time, as registration for the semester is time-sensitive for the students. This includes both denial and approval decisions.</p>
                        <p><b>PERMITS ARE REQUIRED FOR ALL DEPARTMENT APPROVALS</b>
                        <br/>When approving any request, be reminded to place an appropriate permit on that section during the approval process, so the student may enroll.
                        <br/><a href="https://csuf-erp.screenstepslive.com/m/70028/l/1559224" target='_blank' rel="noopener noreferrer">How to Place a Permit</a></p>
                        {/*<p>SAMPLE PERMIT PLACEMENT</p>
                        <img src={SamplePermitPlacement}></img>*/}
                        </Typography>
                    }
                    
                    <br/>
                     
                    {/*<Paper square >
                    <Tabs
                        value={this.state.value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        aria-label="disabled tabs example"
                    >
                        <Tab label="Pending" icon={<Restore/>} value={2} />
                        <Divider orientation="vertical" flexItem />
                        <Tab label="Approved" icon={<CheckCircleOutline/>} value={1} />
                        <Divider orientation="vertical" flexItem />
                        <Tab label="Denied" icon={<Block/>} value={0}/>
                        <Divider orientation="vertical" flexItem />
                        <Tab label="Alerts" icon={<NotificationsNone/>} value={3}/>
                        <Divider orientation="vertical" flexItem />
                    </Tabs>
                    </Paper>*/}
                    <TableContainer>
                        <Table size="medium">
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th" scope="row" padding="none"></TableCell>
                                    <TableCell align="left" padding="none"><Typography variant="caption"><strong>Student Name</strong></Typography></TableCell>
                                    <TableCell align="left"><Typography variant="caption"><strong>Tracking Code</strong></Typography></TableCell>
                                    <TableCell align="left"><Typography variant="caption"><strong>Term</strong></Typography></TableCell>
                                    <TableCell align="left"><Typography variant="caption"><strong>Class</strong></Typography></TableCell>
                                    <TableCell align="left"><Typography variant="caption"><strong>Approval Status</strong></Typography></TableCell>
                                    <TableCell align="left"><Typography variant="caption"><strong>Submitted Date</strong></Typography></TableCell>
                                    <TableCell align="left" padding="none"><Typography variant="caption"><strong>Actions</strong></Typography></TableCell>
                                    <TableCell align="left" padding="none"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {/*filter(x => x.status == this.state.value)*/}
                            {this.state.approvalrequests.map(x => (
                                <TableRow>
                                    <TableCell component="th" scope="row" padding="none"><Avatar style={{ backgroundColor : x.status === 1 ? green[600] : ( x.status === 0 ?red[600]: ''),width: 35, height: 35}}>
                                    <FileCopy fontSize="small"/>
                                    </Avatar></TableCell>
                                    <TableCell align="left">
                                    <Typography variant="caption">{x.firstname} {x.lastname}</Typography><br/> 
                                    <Typography variant="caption">CWID: {x.cwid}</Typography>
                                    </TableCell>
                                    <TableCell align="left"><Typography variant="caption">{x.referenceid}</Typography></TableCell>
                                    <TableCell align="left"><Typography variant="caption">{x.term}</Typography></TableCell>
                                    <TableCell align="left"><Typography variant="caption">{x.desc}</Typography><br/><Typography variant="caption">Course: {x.subject}-{x.catalognbr}-{x.section}</Typography>, <Typography variant="caption">Class#: {x.classnbr}</Typography></TableCell>
                                    <TableCell align="left" ><Typography variant="caption">{x.status === 1 ? 'Approved' : x.status === 0 ? 'Denied': 'Pending'}</Typography></TableCell>
                                    <TableCell align="left"><Typography variant="caption">{new Date(x.submitteddate).toLocaleDateString()}</Typography></TableCell>
                                    {/*{x.status == 2 ? '' : <TableCell align="right" padding="none"><Button variant="contained" size="small" color="secondary" onClick={this.onOpenModal} endIcon={<SettingsBackupRestore/>}>Reset</Button></TableCell>}*/}
                                    <TableCell align="left" padding="none"><Button variant="contained" style={{marginLeft: 10}} size="small" color="primary" href={`../app/approval?id=${x.id}&accountid=${this.state.accountid}`} endIcon={<KeyboardArrowRight/>}>View</Button></TableCell>
                                    
                                    {/*href={`https://extension.fullerton.edu/app/approval/${x.id}`} target="_blank"*/}
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Grid>
                    </Paper>
                </Container>
                <Footer/>
                <div style={{ marginTop: 30}}>

                </div>
            </>
        );
    }
}
export default withStyles(styles)(ApprovalDashboard);